import { useState } from "react";
import { FormattedMessage } from "react-intl";
import AppleSignIn from "../../oauth2/AppleSignIn";
import OAuthButton, { OAuthSectionWrapper } from "./OAuthButtonsStyles";
import AuthService from "../../service/AuthService";
import { Title } from "../Subscription/SignupStyles";

const OAuthButtons = ({ location }) => {
  const [loading, setLoading] = useState({});
  const authService = AuthService.getInstance();

  const handleSignIn = (e, service) => {
    e.preventDefault();
    setLoading({ ...loading, [service]: true });
    authService.initAuthCodeFlow("", service);
  };
  return (
    <OAuthSectionWrapper>
      {location === "signin" ? (
        <Title>
          {" "}
          <FormattedMessage
            id="template.sign_in_with_social"
            defaultMessage="Sign in with Social"
          />
        </Title>
      ) : (
        <Title>
          {" "}
          <FormattedMessage
            id="template.sign_in_with_social"
            defaultMessage="Sign in with Social"
          />
        </Title>
      )}

      <AppleSignIn loading={loading} setLoading={setLoading} />
      {/*
      <OAuthButton
        service={"facebook"}
        onClick={(e) => {
          handleSignIn(e, "facebook");
        }}
        loading={loading["facebook"]}
      >
        <FormattedMessage
          id="template.sign_in_with_facebook"
          defaultMessage="Sign in with Facebook"
        />
      </OAuthButton>
         */}
      <OAuthButton
        service={"google"}
        onClick={(e) => {
          handleSignIn(e, "google");
        }}
        loading={loading["google"]}
      >
        <FormattedMessage
          id="template.sign_in_with_google"
          defaultMessage="Sign in with Google"
        />
      </OAuthButton>
    </OAuthSectionWrapper>
  );
};

export default OAuthButtons;
