import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { dateFormatter } from "../Helpers/misc";
import styled from "@emotion/styled/macro";
import { TitleSpan } from "./RecentTable";

const mythLink = (data) => {
  return (
    <Link
      to={`/partner/myth/profile/${data.mythProfileId}`}
      className="label-link"
    >
      {data.title || data.identifier}
    </Link>
  );
};

function RecentColumns(props) {
  const columns = useMemo(
    () => [
      {
        Header: "Published",
        accessor: "firstPublishedDate",
        Cell: (data) => {
          return dateFormatter(data.cell.value);
        },
        width: 275,
      },
      {
        Header: "Name",
        accessor: "identifier",
        disableSortBy: true,
        width: 730,
        className: "domain",
        Cell: (data) => {
          return (
            <>
              <TitleSpan>{mythLink(data.cell.row.original)}</TitleSpan>
            </>
          );
        },
      },
      {
        Header: () => <div className="region">Locale</div>,
        accessor: "country",
        Cell: (data) => {
          return <div className="region">{data.cell.row.original.locale}</div>;
        },
        disableSortBy: true,
        width: 120,
      },
    ],
    []
  );
  return columns;
}

export default RecentColumns;
