import styled from "@emotion/styled/macro";

import webMetadata from "../../../../../../../shared/resources/_metadata.json";
import tvMetadata from "../../../../../../../shared/resources/_tv_metadata.json";
import podcastMetadata from "../../../../../../../shared/resources/_podcast_metadata.json";
import stubMetadata from "../../../../../../../shared/resources/_stub_metadata.json";
import countryList from "../../../../../../../shared/resources/_country.json";

import { Link } from "react-router-dom";
import React from "react";
import Tip from "./common/Tooltip";
import { AsideHeaderStyles } from "./Myths/MythStyles";

const MetadataContainerStyles = styled.div`
  column-count: 2;
  column-width: 10rem;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    column-count: 1;
  }

  p {
    font-size: 20px;
    font-weight: 300;

    line-height: 30px;

    @media screen and (max-width: 600px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  .social {
    text-transform: none;
    @media screen and (max-width: 600px) {
      word-break: break-all;
    }
  }

  div {
    break-inside: avoid;
    margin-right: 40px;

    margin-bottom: 65px;
    @media screen and (max-width: 600px) {
      margin-bottom: 25px;
    }
  }
`;

const Header3 = styled.h3`
  font-size: 20px;
  margin: 0 0 20px 0;
  display: flex;
  align-items: baseline;
  gap: 5px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    font-variation-settings: "wght" 600;
    margin: 0 0 10px 0;
  }
`;

const ColumnStyles = styled.div`
  width: 560px;
  margin-right: 40px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-right: 0;
  }

  div {
    display: inline-grid;
    page-break-inside: avoid;
    margin-bottom: 65px;
    @media screen and (max-width: 600px) {
      margin-bottom: 25px;
    }
  }
  .wide {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

const isTheValueIn = (_values, val) => {
  if (_values && _values[0]) {
    const valIndex = _values.indexOf(val);
    if (valIndex !== -1) {
      return true;
    }
  }
  return false;
};

const TipStyle = styled.div`
  white-space: pre-wrap;
`;

const MetadataSectionTextStyles = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  a {
    text-decoration: none;
    color: #3355dd;
    word-break: break-all;
  }

  &:last-of-type {
    margin-bottom: 35px;
  }
`;

const MetadataUL = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MetadataListItem = styled.li`
  margin-bottom: ${(props) => (props.type === "multiLine" ? "10px" : "0")};
`;

const MetadataHeader = ({ title, tooltip }) => (
  <Header3>
    {title}{" "}
    {tooltip && (
      <Tip>
        <TipStyle>{tooltip}</TipStyle>
      </Tip>
    )}
  </Header3>
);

export { MetadataUL, MetadataListItem, MetadataHeader };

export function MetadataListWrapper({
  value,
  metadataDef,
  link = false,
  context,
}) {
  if (value) {
    if (!Array.isArray(value)) {
      value = value.split(",");
    }

    value = value
      .filter(
        (term) =>
          term && ((typeof term !== "object" && term.trim()) || term.value)
      )
      .map((term) => (typeof term !== "object" ? term : term.value));

    return (
      value.length > 0 && (
        <section>
          <AsideHeaderStyles>
            {metadataDef.title}
            <Tip>{metadataDef.displayInfo}</Tip>
          </AsideHeaderStyles>
          <MetadataList
            context={context}
            value={value}
            metakey={metadataDef.key}
            link={link}
          />
        </section>
      )
    );
  }
  return "";
}

export function MetadataList({ value, metakey, link, context, type }) {
  let list = [];
  if (Array.isArray(value)) {
    list = value;
  } else {
    list = value.split(",");
  }

  return (
    <MetadataUL>
      {list.map((term, index) => {
        let displayValue = term;
        if (metakey === "location" || metakey === "spread_location") {
          const countryDisplayList = countryList.filter(
            (countryVal) => countryVal.id === term
          );
          if (countryDisplayList && countryDisplayList.length) {
            displayValue = countryDisplayList[0].label;
          }
        }
        return link ? (
          metakey ? (
            <MetadataListItem key={index} type={type}>
              <Link
                to={`/partner/search?search_context=${context}&${metakey}=${term}`}
              >
                {displayValue}
              </Link>
            </MetadataListItem>
          ) : (
            <MetadataSectionTextStyles key={index}>
              <Link to={`${term}`} target="_blank" rel="noopener noreferrer">
                {displayValue}
              </Link>
            </MetadataSectionTextStyles>
          )
        ) : (
          <MetadataListItem key={index} type={type}>
            {displayValue}
          </MetadataListItem>
        );
      })}
    </MetadataUL>
  );
}

export default function Metadata(props) {
  const { label, parents } = props;

  const { metadata } = label;
  let metadataDef = tvMetadata;
  if (label.network === "PODCAST") {
    metadataDef = podcastMetadata;
  } else if (label.network === "WEB") {
    if (label.stub) {
      metadataDef = stubMetadata;
    } else {
      metadataDef = webMetadata;
    }
  }

  return (
    <MetadataContainerStyles>
      <>
        {label.network === "WEB" && !label.stub ? (
          <>
            <div>
              <MetadataHeader
                title={"Brand Name"}
                tooltip={metadataDef.brand?.displayInfo}
              />
              <p>{metadata.brand?.body || "--"}</p>
            </div>
            <div>
              <MetadataHeader
                title={"Country"}
                tooltip={
                  " This field displays a two-letter code identifying the country that the Nutrition Label applies to — for example, US for the United States or FR for France. Note: Websites with different versions in different countries may have distinct Nutrition Labels and ratings for each covered country."
                }
              />
              <p>{label.country || "--"}</p>
            </div>
            <div>
              <MetadataHeader
                title={"Language"}
                tooltip={
                  " A two-letter code identifying the language that the label is written in—for example, en (English), fr (French), it (Italian), de (German), or es (Spanish)."
                }
              />
              <p>{label.language || "--"}</p>
            </div>
          </>
        ) : (
          ""
        )}
        <>
          {Object.keys(metadataDef).map((key, index) => {
            const metadataDefElement = metadataDef[key];
            let enabled =
              !metadataDefElement.internalOnly &&
              !metadataDefElement.hide_dashboard &&
              (metadataDefElement.key !== "brand" || label.network !== "WEB");

            if (enabled && metadataDefElement.condition) {
              if (metadata[metadataDefElement.condition?.key]) {
                if (
                  metadata[metadataDefElement.condition?.key].multiple &&
                  tvMetadata[metadataDefElement.condition?.key].body
                ) {
                  if (
                    tvMetadata[
                      metadataDefElement.condition?.key
                    ]?.body?.indexOf(metadataDefElement.condition?.value) === -1
                  ) {
                    enabled = false;
                  }
                } else if (
                  metadata[metadataDefElement.condition?.key]?.body !==
                  metadataDefElement.condition?.value
                ) {
                  enabled = false;
                }
              } else {
                // if the condition key is not with the metadata, it is a property of publisher
                if (label[metadataDefElement.condition?.key]) {
                  enabled =
                    label[metadataDefElement.condition?.key] ===
                    metadataDefElement.condition?.value;
                }
              }
            }

            if (enabled) {
              if (metadataDefElement.key === "network" && parents?.length > 0) {
                // special case of network
                // if the tv program is associated with a network, instead of the metadata, use the associated network
                //
                return (
                  <div key={metadataDefElement.key}>
                    <MetadataHeader
                      title={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.title
                      }
                      tooltip={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.displayInfo
                      }
                    />
                    <p>
                      {parents.map((parent, idx) => {
                        return (
                          <div key={parent.identifier}>
                            <Link
                              to={`/partner/label/${parent.id}`}
                              dangerouslySetInnerHTML={{
                                __html: parent.identifier,
                              }}
                            />
                            {idx + 1 < parents.length ? "," : ""}{" "}
                          </div>
                        );
                      })}
                    </p>
                  </div>
                );
              } else if (metadataDefElement.key === "owner") {
                return (
                  <div key={metadataDefElement.key}>
                    <MetadataHeader
                      title={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.title
                      }
                      tooltip={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.displayInfo
                      }
                    />
                    <p>
                      {label.network === "WEB" ? (
                        metadata.owner?.body ? (
                          <a
                            href={`/partner/search?search_context=${label.network}&owner=${metadata.owner?.body}`}
                          >
                            {metadata.owner?.body}
                          </a>
                        ) : (
                          "--"
                        )
                      ) : metadata.owner?.body ? (
                        metadata.owner?.body
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>
                );
              } else if (
                metadataDefElement.ui === "checkbox" ||
                metadataDefElement.ui === "radio"
              ) {
                const options = metadataDefElement.ui_values
                  ? metadataDefElement.ui_values
                  : metadataDefElement.values;

                let values =
                  metadata[metadataDefElement.key]?.body ||
                  metadataDefElement?.default ||
                  "";

                if (values && metadataDefElement.multiple) {
                  if (!Array.isArray(values)) {
                    values = values.split(",");
                  }
                }

                if (values && !Array.isArray(values)) {
                  values = [values];
                }

                return (
                  <div key={metadataDefElement.key}>
                    <MetadataHeader
                      title={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.title
                      }
                      tooltip={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.displayInfo
                      }
                    />
                    {(values &&
                      values.length > 0 &&
                      options.map((option) => {
                        if (option) {
                          if (option.key) {
                            return isTheValueIn(values, option.key) ? (
                              <p key={option.value}>{option.value}</p>
                            ) : (
                              ""
                            );
                          } else {
                            return isTheValueIn(values, option) ? (
                              <p key={option}>{option}</p>
                            ) : (
                              ""
                            );
                          }
                        }
                        return null;
                      })) ||
                      "---"}
                  </div>
                );
              } else {
                return (
                  <div key={metadataDefElement.key}>
                    <MetadataHeader
                      title={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.title
                      }
                      tooltip={
                        metadataDefElement.hide_dashboard
                          ? ""
                          : metadataDefElement.displayInfo
                      }
                    />
                    <p>
                      {metadata[metadataDefElement.key]?.body ||
                        metadataDefElement?.default ||
                        "--"}
                    </p>
                  </div>
                );
              }
            }
            return null;
          })}
        </>
      </>

      {label.network === "WEB" &&
      label.socialAccounts &&
      label.socialAccounts.length ? (
        <ColumnStyles>
          <div>
            <MetadataHeader
              title={"Social Accounts"}
              tooltip={
                "The URLs of social media accounts associated with the website."
              }
            />
            {label.socialAccounts.map((socialAccount) => (
              <p
                className={`social ${socialAccount.type}`}
                key={socialAccount.accountHandle}
              >
                {socialAccount.accountHandle}{" "}
              </p>
            ))}
          </div>
        </ColumnStyles>
      ) : (
        ""
      )}
      {label.network === "WEB" && (!label.stub || label.rank === "FL") ? (
        <div>
          <MetadataHeader
            title={"BrandGuard Segments"}
            tooltip={
              "For advertisers using NewsGuard to target advertising placements, NewsGuard provides “segments” of rated sites based on brand safety levels. For more information about these segments, contact your NewsGuard account representative."
            }
          />
          {metadata.bg_segments?.body && metadata.bg_segments?.body.length
            ? metadata.bg_segments?.body.map((seg, idx) => {
                return <p key={seg}>{seg}</p>;
              })
            : "--"}
        </div>
      ) : (
        ""
      )}
    </MetadataContainerStyles>
  );
}
