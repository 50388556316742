import styled from "@emotion/styled/macro";
import { ButtonBluePitch, ButtonWhitePitch } from "../../FormElements/Button";
import { ButtonsWrapper } from "../Settings/SettingsStyles";
import { unauthorizedCopy } from "./unauthorizedCopy";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import TagManager from "react-gtm-module";

const Wrapper = styled.div`
  max-width: 98ch;
  line-height: 1.85;
  margin: 60px auto 0;
`;

const Paragraph = styled.p`
  margin-bottom: 1.5rem;
`;

const HeroWrapper = styled.section`
  background-color: var(--primary-blue);
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  min-height: 340px;
  display: flex;
`;

const Hero = styled.div`
  color: var(--primary-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0 30px 0;
  width: fit-content;
  max-width: 960px;
  margin: 0 auto;
`;

const Header2 = styled.h2`
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
`;

const HeroHeader = styled(Header2)`
  font-weight: 600;
`;

const CTA = styled.div`
  font-size: var(--16px);
  line-height: 30px;
  margin-top: 25px;
  margin-bottom: 20px;
  width: 70ch;

  @media (max-width: 900px) {
    width: 80%;
  }
`;

const Link = styled.a`
  color: var(--primary-white);
  text-decoration: underline;

  &:hover {
    color: var(--primary-white);
  }
`;

const LinkPitchButton = styled(Link)`
  color: ${({ color }) => color || "var(--primary-white)"};
  line-height: 35px;
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 0 20px;

  text-decoration: none;

  &:hover {
    color: ${({ color }) => color || "var(--primary-white)"};
    text-decoration: none;
  }
`;

const PitchButtonsWrapper = styled(ButtonsWrapper)`
  gap: 20px;
  margin-top: 20px;
`;

const UnorderedList = styled.ul`
  column-count: 2;
  column-gap: 50px;
  padding: 0;
  margin-bottom: 45px !important;
  list-style: none;

  @media (max-width: 600px) {
    column-count: 1;
  }
`;

const ListItem = styled.li`
  color: #000;
  font-size: 16px;
  line-height: 30px;
  font-family: Sora, sans-serif;
  margin-bottom: 30px;

  padding-left: 25px;
  position: relative;

  &::before {
    content: "";
    height: 10px;
    width: 10px;
    background-color: #35d;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 9px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const MainContent = styled.div`
  margin-top: -60px;
  padding: 0 20px;
`;

const MainContentHeader = styled(Header2)`
  font-weight: 600;
  margin-bottom: 20px;
`;

const Unauthorized = ({ type = "DEFAULT" }) => {
  const principal = useSelector((state) => state.authentication?.principal);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        user: principal?.username,
        partner: principal?.attributes?.partner,
        client_id: principal?.attributes?.client_id,
        view: "Unauthorized",
        content_type: type,
        content_title: "",
        content_locale: "",
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
    <MainContent>
      <HeroWrapper>
        <Hero>
          <HeroHeader>{unauthorizedCopy[type].header}</HeroHeader>

          <CTA>
            {unauthorizedCopy[type].body}{" "}
            <Link
              href={unauthorizedCopy[type].linkUrl}
              target="_blank"
              rel="noreferrer"
            >
              {unauthorizedCopy[type].linkText}
            </Link>{" "}
            {unauthorizedCopy[type].linkConnector}{" "}
            <Link href={unauthorizedCopy[type].emailUrl}>
              {unauthorizedCopy[type].email}
            </Link>
            .
          </CTA>
          <PitchButtonsWrapper>
            <ButtonWhitePitch>
              <LinkPitchButton
                href={unauthorizedCopy[type].linkUrl}
                target="_blank"
                rel="noreferrer"
                color="var(--primary-blue)"
              >
                Contact us
              </LinkPitchButton>
            </ButtonWhitePitch>
            <ButtonBluePitch>
              <LinkPitchButton
                href={unauthorizedCopy[type].learnMoreLink}
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </LinkPitchButton>
            </ButtonBluePitch>
          </PitchButtonsWrapper>
        </Hero>
      </HeroWrapper>
      <Wrapper>
        <MainContentHeader>
          {unauthorizedCopy[type].listHeader}
        </MainContentHeader>
        <UnorderedList>
          {unauthorizedCopy[type].bullets?.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </UnorderedList>
        <Paragraph>{unauthorizedCopy[type].summation}</Paragraph>
      </Wrapper>
      {/* {myth ? (
       <Wrapper>
       <h1>Misinformation Fingerprints™ </h1>
       <Paragraph>
       Your account license does not authorize you to access
       NewsGuard&apos;s Misinformation Fingerprints™ data about false
       narratives spreading online. To expand your organization&apos;s
       NewsGuard license to gain access to this content,{" "}
       <a
       href="https://www.newsguardtech.com/feedback/give/"
       target="_blank"
       rel="noreferrer"
       >
       contact us
       </a>{" "}
       or email{" "}
       <a href="mailto:partnerships@NewsGuardtech.com?subject=Misinformation Fingerprints Licensing">
       partnerships@NewsGuardtech.com
       </a>
       .
       </Paragraph>
       <Paragraph>NewsGuard&apos;s Misinformation Fingerprints:</Paragraph>
       <List>
       <ListItem>
       Offer a constantly-updated library of the top misinformation and
       disinformation narratives spreading on social media and the open
       web, especially from state-sponsored sources
       </ListItem>

       <ListItem>
       {" "}
       Are conducted by a team of experienced journalists
       </ListItem>

       <ListItem>
       Contain multiple categories of data for each narrative, including
       a description of the falsehood, a description of the facts, search
       terms, subject tags, keywords, hashtags, variations, links to
       articles and social media posts advancing the falsehood, and links
       to reliable sources debunking the falsehood.
       </ListItem>

       <ListItem>
       Are available in human-readable format (via the NewsGuard
       dashboard) and machine-readable via a real-time data feed and API
       </ListItem>

       <ListItem>
       Misinformation Fingerprints™ are used by defense agencies tracking
       state-sponsored disinformation campaigns, cybersecurity companies,
       platforms conducting content moderation, researchers, and other
       clients that need to understand and track mis- and disinformation
       narratives.
       </ListItem>
       </List>
       </Wrapper>
       ) : label?.network === "TV" ? (
       <Wrapper>
       <h1>NewsGuard&apos;s TV Reliability Ratings</h1>
       <Paragraph>
       Your account license does not authorize you to access
       NewsGuard&apos;s reliability ratings for television news programs
       and networks. To expand your organization&apos;s NewsGuard license
       to gain access to this content,{" "}
       <a
       href="https://www.newsguardtech.com/feedback/give/"
       target="_blank"
       rel="noreferrer"
       >
       contact us
       </a>{" "}
       or email{" "}
       <a href="mailto:partnerships@NewsGuardtech.com?subject=Misinformation Fingerprints Licensing">
       partnerships@NewsGuardtech.com
       </a>
       .
       </Paragraph>
       <Paragraph>NewsGuard&apos;s TV Reliability Ratings:</Paragraph>
       <List>
       <ListItem>
       Cover the top news and information networks and programs on
       television and CTV Are conducted by a team of experienced
       journalists
       </ListItem>
       <ListItem>
       Score each program or network on a set of journalistic credibility
       criteria
       </ListItem>
       <ListItem>
       Provide a rating level, numerical score, and metadata fields
       describing each program or network
       </ListItem>
       <ListItem>
       Include a detailed written report describing each news source
       </ListItem>
       </List>
       <Paragraph>
       TV Reliability Ratings are used by brands, agencies, and advertising
       platforms to direct advertisements to credible, brand-suitable news
       content and avoid risky or unreliable news content. The ratings can
       be used for planning for direct and linear ad buys, for contextual
       CTV and OTT ad targeting, and more.
       </Paragraph>
       </Wrapper>
       ) : label?.network === "PODCAST" ? (
       <Wrapper>
       <h1>NewsGuard&apos;s Podcast Reliability Ratings</h1>
       <Paragraph>
       Your account license does not authorize you to access
       NewsGuard&apos;s reliability ratings for news and information
       podcasts. To expand your organization&apos;s NewsGuard license to
       gain access to this content,{" "}
       <a
       href="https://www.newsguardtech.com/feedback/give/"
       target="_blank"
       rel="noreferrer"
       >
       contact us
       </a>{" "}
       or email{" "}
       <a href="mailto:partnerships@NewsGuardtech.com">
       spartnerships@NewsGuardtech.com
       </a>
       .
       </Paragraph>
       <Paragraph>NewsGuard&apos;s Podcast Reliability Ratings:</Paragraph>
       <List>
       <ListItem>
       Cover the top news and information podcasts across major
       podcasting platforms
       </ListItem>
       <ListItem>
       Are conducted by a team of experienced journalists
       </ListItem>
       <ListItem>
       Score each podcast or podcast network based on a set of
       journalistic credibility criteria
       </ListItem>
       <ListItem>
       Provide a rating level, numerical score, and metadata fields
       describing each podcast or podcast network
       </ListItem>
       <ListItem>
       Include a detailed written report describing each podcast or
       podcast network
       </ListItem>
       </List>
       <Paragraph>
       Podcast reliability ratings are used by brands, agencies, and
       podcasting platforms to direct advertisements to credible,
       brand-suitable news content and avoid risky or unreliable news
       content. The ratings can be used for planning for direct ad buys,
       for contextual programmatic ad targeting, and more.
       </Paragraph>
       </Wrapper>
       ) : label?.network === "WEB" ? (
       <Wrapper>
       <MainContentHeader>
       NewsGuard&apos;s Reliability Ratings
       </MainContentHeader>
       <UnorderedList>
       <ListItem>
       Are conducted by a team of experienced journalists
       </ListItem>
       <ListItem>
       Score each site on nine journalistic criteria related to
       credibility and transparency
       </ListItem>
       <ListItem>
       Provide a rating level, 0-100 score, and 40+ metadata fields
       describing each site
       </ListItem>
       <ListItem>
       Identify a site&apos;s ownership and financing sources
       </ListItem>
       <ListItem>
       Include a detailed written report describing each news source
       </ListItem>
       <ListItem>
       Cover all of the news and information sources accounting for 95%+
       of online engagement with news
       </ListItem>
       <ListItem>
       Are available in human-readable format (via the NewsGuard
       dashboard) and machine-readable format via a real-time data feed
       and API
       </ListItem>
       </UnorderedList>

       <Paragraph>
       Reliability Ratings are used by platforms for content moderation or
       policy enforcement, by advertising companies to target ads to
       trustworthy sources while avoiding ad placements on misinformation,
       and by other clients to inform and equip internal teams or end users
       with basic news literacy information to distinguish credible sources
       from unreliable ones.
       </Paragraph>
       <PitchButtonsWrapper>
       <ButtonBluePitch>
       <LinkPitchButton
       href="https://www.newsguardtech.com/feedback/give/"
       target="_blank"
       rel="noreferrer"
       >
       Contact us
       </LinkPitchButton>
       </ButtonBluePitch>
       </PitchButtonsWrapper>
       </Wrapper>
       ) : (
       <Wrapper>
       <Paragraph>
       Your account license does not authorize you to access this page. To
       learn more or expand your organization&apos;s NewsGuard license to
       gain access to this content,{" "}
       <a
       href="https://www.newsguardtech.com/feedback/give/"
       target="_blank"
       rel="noreferrer"
       >
       contact us
       </a>{" "}
       or email{" "}
       <a href="mailto:partnerships@NewsGuardtech.com">
       partnerships@NewsGuardtech.com
       </a>
       .
       </Paragraph>
       </Wrapper>
       )} */}
    </MainContent>
  );
};

export default Unauthorized;
