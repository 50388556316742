import NetworkService from "./NetworkService";
import AppReduxStore from "../AppReduxStore";
import ConfigService from "./ConfigService";
import Cookies from "js-cookie";
import AccountService from "./AccountService";
import { setSubscribed, setSuspended } from "../_actions";

const AuthService = (() => {
  let instance;

  function createInstance() {
    return new AuthServiceInstance();
  }

  return {
    getInstance: function (config) {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default AuthService;

class AuthServiceInstance {
  networkService = NetworkService.getInstance();
  loginUrl = "";
  logoutUrl = "";
  revokeTokenUrl = "";
  redirectUri = "";
  clientId = "";
  userInfoUrl = "";
  scope = "";
  context = "/";
  _storage = localStorage;
  rngUrl = "";
  oauthState = {};
  clearHashAfterLogin = true;
  principal = {};
  verifyAppleIdCodeUrl = "";
  setStorage = (storage) => {
    this._storage = storage;
  };

  saveRoute = (route = window.location.pathname) => {
    sessionStorage.setItem("_auth_return_location", route);
  };

  getSavedRoute = () => {
    return sessionStorage.getItem("_auth_return_location");
  };

  redirectToSavedRoute = () => {
    const returnLocation = this.getSavedRoute();
    if (returnLocation) {
      sessionStorage.removeItem("_auth_return_location");
      window.location = returnLocation;
    }
  };

  saveEmail = (email) => {
    localStorage.setItem("_user_email", email);
  };

  getEmail = () => {
    return localStorage.getItem("_user_email");
  };

  createAndSaveNonce = async () => {
    const that = this;
    return this.createNonce().then(function (nonce) {
      that._storage.setItem("nonce", nonce);
      return nonce;
    });
  };

  createNonce = () => {
    return new Promise((resolve, reject) => {
      if (this.rngUrl) {
        throw new Error(
          "createNonce with rng-web-api has not been implemented so far"
        );
      } else {
        let text = "";
        const possible =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < 40; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));

        resolve(text);
      }
    });
  };

  processSignInByLink = () => {
    const parts = this.getFragment();
    const accessToken = parts["access_token"];
    if (accessToken) {
      this.setAccessToken(parts);
      window.location.hash = "";
      return true;
    } else {
      this.logOut();
    }
    return false;
  };

  createLoginUrl = (oauthState, provider = "", response_type = "code") => {
    const that = this;

    if (typeof oauthState === "undefined") {
      oauthState = "";
    }

    return this.createAndSaveNonce().then(function (nonce) {
      if (oauthState) {
        oauthState = nonce + ";" + oauthState;
      } else {
        oauthState = nonce;
      }

      let url =
        that.loginUrl +
        "?response_type=" +
        response_type +
        "&client_id=" +
        encodeURIComponent(that.clientId) +
        "&state=" +
        encodeURIComponent(oauthState) +
        "&redirect_uri=" +
        encodeURIComponent(that.redirectUri) +
        "&scope=" +
        encodeURIComponent(that.scope) +
        "&provider=" +
        encodeURIComponent(provider);
      if (that.resource) {
        url += "&resource=" + encodeURIComponent(that.resource);
      }

      if (that.oidc) {
        url += "&nonce=" + encodeURIComponent(nonce);
      }

      return url;
    });
  };

  initImplicitFlow = (additionalState = "", provider = "") => {
    this.createLoginUrl(additionalState, provider, "token")
      .then(function (url) {
        window.location.href = url;
      })
      .catch(function (error) {
        console.error("Error in initImplicitFlow");
        console.error(error);
      });
  };

  initAuthCodeFlow = (additionalState = "", provider = "") => {
    this.createLoginUrl(additionalState, provider, "code")
      .then(function (url) {
        window.location.href = url;
      })
      .catch(function (error) {
        console.error("Error in initAuthCodeFlow");
        console.error(error);
      });
  };

  triggerUserVoucher = () => {
    window.self.dispatchEvent(
      new CustomEvent("user_voucher_action", { detail: {} })
    );

    // deprecated ...
    window.self.postMessage(
      { action: "user_voucher_action" },
      window.location.origin
    );
  };

  triggerUserSignIn = (passToApp = false) => {
    window.self.dispatchEvent(
      new CustomEvent("user_sign_action", { detail: { passToApp: passToApp } })
    );

    // deprecated ...
    window.self.postMessage(
      { action: "user_sign_action" },
      window.location.origin
    );
  };

  processAuthFlow = (url, data) => {
    const token = window.btoa(this.clientId + ":" + this.clientSecret);
    const headers = {
      Authorization: `Basic ${token}`,
      "Content-Type": "application/x-www-form-urlencoded",
    };

    return this.networkService
      .postForm(url, data, headers)
      .then((response) => {
        this.processTokenResponse(response);
        return Promise.resolve(response);
      })
      .catch((e) => Promise.reject(e));
  };

  processTokenResponse = (response) => {
    const accessToken = response["access_token"];
    const expiresIn = response["expires_in"];
    const refreshToken = response["refresh_token"] || "";

    this._storage.setItem("access_token_raw", JSON.stringify(response));
    this.storeAccessTokenResponse(accessToken, refreshToken, expiresIn);
    this.triggerUserSignIn();
  };

  passwordFlow = (loginRequest) => {
    // OAuth2 Password flow is being deprecated
    // to Continue the functionalit, an ad-hoc endpoint replaces the default password flow token

    /*
     //
     // POST http://localhost:8083/oauth/token with password flow to sign in by API
     // body - username, password, grant_type, client_id
     // headers - Content-Type : application/x-www-form-urlencoded or application/json
     //          Authorization : Basic client_id:client_secret
     //
     const url = this.revokeTokenUrl;
     */

    const url = this.authServiceRoot + "/user/signin";
    const data = {
      //grant_type: "password",
      //client_id: this.clientId,
      username: loginRequest.email,
      //email: loginRequest.email,
      password: loginRequest.password,
    };

    const token = window.btoa(this.clientId + ":" + this.clientSecret);
    const headers = {
      Authorization: `Basic ${token}`,
      //"Content-Type": "application/x-www-form-urlencoded",
    };

    return (
      this.networkService
        .post(url, data, headers)
        //.postForm(url, data, headers)
        .then((response) => {
          this.processTokenResponse(response);
          return Promise.resolve(response);
        })
        .catch((e) => Promise.reject(e))
    );
  };

  authCodeFlow = (code) => {
    //
    // POST http://localhost:8083/oauth/token with password flow to sign in by API
    // body - username, password, grant_type, client_id
    // headers - Content-Type : application/x-www-form-urlencoded or application/json
    //          Authorization : Basic client_id:client_secret
    //
    const url = this.revokeTokenUrl;
    const data = {
      grant_type: "authorization_code",
      client_id: this.clientId,
      code: code,
      redirect_uri: this.redirectUri,
    };
    return this.processAuthFlow(url, data);
  };

  verifyAppleIdCode = (code) => {
    const token = window.btoa(this.clientId + ":" + this.clientSecret);
    const headers = {
      Authorization: `Basic ${token}`,
    };
    return this.networkService
      .post(this.verifyAppleIdCodeUrl, code, headers)
      .then((response) => {
        this.processTokenResponse(response);
        return Promise.resolve(response);
      });
  };

  signup = async (signupRequest) => {
    const token = window.btoa(this.clientId + ":" + this.clientSecret);
    const headers = {
      Authorization: `Basic ${token}`,
    };

    return await this.networkService
      .post(this.signupUrl, signupRequest, headers)
      .then(async (response) => {
        this.processTokenResponse(response);
        await this.retrieveUserInfoFromServer();
        return Promise.resolve(response);
      })
      .catch((e) => Promise.reject(e));
  };

  setAccessToken = (parts) => {
    this._storage.setItem("access_token_raw", JSON.stringify(parts));

    const accessToken = parts["access_token"];
    this._storage.setItem("access_token", accessToken);
    Cookies.set("access_token", accessToken, {
      domain: "newsguardtech.com",
      expires: 30,
    });
    this.networkService.setAccessToken(accessToken);
    this._storage.removeItem("nonce");
    this._storage.removeItem("expires_at");
  };

  getAccessToken = () => {
    const accessToken = this._storage.getItem("access_token");
    if (accessToken) {
      const now = new Date();
      const expiresAt = this._storage.getItem("expires_at");
      if (expiresAt) {
        if (now < expiresAt) {
          Cookies.set("access_token", accessToken, {
            domain: "newsguardtech.com",
            expires: 30,
          });
          return accessToken;
        }
      } else {
        Cookies.set("access_token", accessToken, {
          domain: "newsguardtech.com",
          expires: 30,
        });
        return accessToken;
      }

      this._storage.removeItem("access_token");
      Cookies.remove("access_token");
      this._storage.removeItem("nonce");
      this._storage.removeItem("expires_at");
      this._storage.removeItem("voucher");
    }
    return null;
  };

  apiLogout = async () => {
    try {
      const url = `${this.authServiceRoot}/api/logout`;
      return await this.networkService.get(url, {}, "include");
    } catch (e) {
      console.log(e);
      return Promise.resolve();
    }
  };

  logOut = async (doesRedirect = false) => {
    //const token = this.getAccessToken();
    //this.networkService.delete(
    //  that.revokeTokenUrl,
    //  {
    //    headers: {
    //      'Authorization': `Bearer ${token}`,
    //      'Content-Type': 'application/json'
    //    }
    //  }
    //).then(res => {
    //
    //    })
    //      .catch(err => {
    //
    //      });

    Cookies.remove("campaign");
    Cookies.remove("lmref");

    await this.apiLogout();

    this.cleanAccessTokenFromStorage();
    ConfigService.getInstance().resetShownActiveSubs();

    this.triggerUserSignIn(doesRedirect);
    this.triggerUserVoucher();

    if (doesRedirect) {
      window.location.href = this.context;
    }
  };

  requestDelete = async () => {
    try {
      const url = `${this.authServiceRoot}/user/customer`;
      return await this.networkService.delete(url);
    } catch (e) {
      return Promise.resolve();
    }
  };

  cleanAccessTokenFromStorage = () => {
    this._storage.removeItem("access_token");
    Cookies.remove("access_token");
    this._storage.removeItem("nonce");
    this._storage.removeItem("expires_at");
    this._storage.removeItem("access_token_raw");
    this._storage.removeItem("principal");
    this._storage.removeItem("voucher");
    this.principal = {};

    this.networkService.deleteAccessToken();
  };

  authenticated = () => {
    const token = this.getAccessToken();
    return !!token;
  };

  getFragment = () => {
    if (window.location.hash.indexOf("#") === 0) {
      return this.parseQueryString(window.location.hash.substr(1));
    } else {
      return {};
    }
  };

  parseQueryString = (queryString) => {
    let data = {},
      pairs,
      pair,
      separatorIndex,
      escapedKey,
      escapedValue,
      key,
      value;

    if (queryString === null) {
      return data;
    }

    pairs = queryString.split("&");

    for (let i = 0; i < pairs.length; i++) {
      pair = pairs[i];
      separatorIndex = pair.indexOf("=");

      if (separatorIndex === -1) {
        escapedKey = pair;
        escapedValue = null;
      } else {
        escapedKey = pair.substr(0, separatorIndex);
        escapedValue = pair.substr(separatorIndex + 1);
      }

      key = decodeURIComponent(escapedKey);
      value = decodeURIComponent(escapedValue);

      if (key.substr(0, 1) === "/") key = key.substr(1);

      data[key] = value;
    }

    return data;
  };

  storeAccessTokenResponse = (accessToken, refreshToken, expiresIn) => {
    this._storage.setItem("access_token", accessToken);

    if (expiresIn) {
      const expiresInMilliSeconds = expiresIn * 1000;
      const now = new Date();
      const expiresAt = now.getTime() + expiresInMilliSeconds;
      this._storage.setItem("expires_at", "" + expiresAt);
    }
    this.networkService.setAccessToken(accessToken);
  };

  getPrincipal = async (principal = {}) => {
    this.principal = principal;
    //console.log("principal ");
    //console.log(principal);
    if (principal && principal.id) {
      if (
        this.isUnregisteredVoucherUser(principal) ||
        this.isPasswordChangePrivilege(principal)
      ) {
        //console.log(principal);
        this._storage.setItem("principal", principal);
      } else {
        this._storage.removeItem("principal");
      }
      await AccountService.getInstance()
        .isSubscriber()
        .then(async (res) => {
          if (res.message === "suspended") {
            AppReduxStore.dispatch(setSuspended(true));
          } else if (res.message === "active") {
            AppReduxStore.dispatch(setSubscribed(true));
          }
        })
        .catch((err) => {
          console.log(err);
        });

      AppReduxStore.dispatch({
        type: "GET_PRINCIPAL",
        principal: principal,
        authenticated: true,
      });
    } else {
      this.principal = {};
      this.cleanAccessTokenFromStorage();
    }
    return this.principal;
    //return this._storage.getItem("principal") ? JSON.parse(this._storage.getItem("principal")) : {};
  };

  retrieveUserInfoFromServer = () => {
    if (this.authenticated()) {
      const token = this.getAccessToken();
      this.networkService.setAccessToken(token);

      return this.networkService
        .get(this.userInfoUrl)
        .then((res) => {
          return Promise.resolve(this.getPrincipal(res));
        })
        .catch((e) => {
          return Promise.reject(e);
        });
    } else {
      this.principal = {};
      this.networkService.deleteAccessToken("");
      return Promise.reject(this.getPrincipal());
    }
  };

  isUnregisteredVoucherUser = (principal) => {
    let authorities = [];
    if (principal && principal.authorities) {
      principal.authorities.forEach((authority) => {
        authorities.push(authority.authority);
      });
      return (
        authorities.includes("ROLE_VOUCHER") &&
        !authorities.includes("ROLE_CUSTOMER")
      );
    }
    return false;
  };

  isPasswordChangePrivilege = (principal) => {
    let authorities = [];
    if (principal && principal.authorities) {
      principal.authorities.forEach((authority) => {
        authorities.push(authority.authority);
      });
      return (
        authorities.includes("CHANGE_PASSWORD_PRIVILEGE") &&
        !authorities.includes("ROLE_CUSTOMER")
      );
    }
    return false;
  };

  isRegisteredUser = (principal) => {
    let authorities = [];
    if (principal && principal.authorities) {
      principal.authorities.forEach((authority) => {
        authorities.push(authority.authority);
      });
      return authorities.includes("ROLE_CUSTOMER");
    }
    return false;
  };

  isPartner = (principal) => {
    let authorities = [];
    if (principal && principal.authorities) {
      principal.authorities.forEach((authority) => {
        authorities.push(authority.authority);
      });
      return authorities.includes("ROLE_PARTNER");
    }
    return false;
  };

  hasSpecificAccess = (principal, access) => {
    let authorities = [];
    if (principal && principal.authorities) {
      principal.authorities.forEach((authority) => {
        authorities.push(authority.authority);
      });
      return authorities.includes(access);
    }
    return false;
  };

  hasLabelHistoryAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_LABEL_HISTORY");
  };

  hasSearchAccess = (principal) =>
    this.hasPodcastAccess(principal) ||
    this.hasLabelAccess(principal) ||
    this.hasTVAccess(principal) ||
    this.hasMythAccess(principal) ||
    this.hasRecentMythOnlyAccess(principal) ||
    this.hasOnlineReportAccess(principal);

  hasLabelAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_LABEL");
  };

  hasTVAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_TV");
  };

  hasPodcastAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_PODCAST");
  };

  hasMythAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_MYTH");
  };

  hasRecentMythOnlyAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_MYTH_RECENT_ONLY");
  };

  hasDevelopingStoryAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_DEVELOPING_STORY");
  };

  hasOnlineReportAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_REPORT");
  };

  hasAdvancedSearchAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_ADVANCED_SEARCH");
  };

  hasCSVExportAccess = (principal) => {
    return this.hasSpecificAccess(principal, "AUTH_CSV_EXPORT");
  };

  tryLogin = (options) => {
    options = options || {};
    const that = this;

    const parts = this.getFragment();

    //console.log(parts);

    const accessToken = parts["access_token"];
    const oauthState = parts["state"];

    if (!accessToken || !oauthState) {
      return false;
    }

    const stateParts = oauthState.split(";");

    if (stateParts.length > 1) {
      this.oauthState = stateParts[1];
    }

    this._storage.setItem("access_token_raw", JSON.stringify(parts));
    that.storeAccessTokenResponse(accessToken, null, parts["expires_in"]);
    if (this.clearHashAfterLogin) window.location.hash = "";

    return true;
  };

  isAuthorized = () => {
    const accessToken = this.getAccessToken();
    this.networkService.setAccessToken(accessToken);
    const endpoint = `${this.authServiceRoot}/subscription/authorized`;
    return this.networkService.get(endpoint);
  };

  updateConfig = (config) => {
    if (config) {
      this.authServiceRoot = config.authServiceRoot;
      this.resourceServiceRoot = config.resourceServiceRoot;
      this.nutritionServiceRoot = config.nutritionServiceRoot;
      this.loginUrl = this.authServiceRoot + config.loginUrl;
      this.logoutUrl = this.authServiceRoot + config.logoutUrl;
      this.revokeTokenUrl = this.authServiceRoot + config.revokeTokenUrl;
      this.signupUrl = this.authServiceRoot + config.signupUrl;
      this.userInfoUrl = this.authServiceRoot + config.userInfoUrl;

      this.redirectUri = config.redirectUri;
      this.clientId = config.clientId;
      this.clientSecret = config.clientSecret;

      this.scope = config.scope;
      this.verifyAppleIdCodeUrl = config.verifyAppleIdCodeUrl;
    }
  };

  resetPassword = (email) => {
    const headers = {};
    const token = window.btoa(this.clientId + ":" + this.clientSecret);
    headers.Authorization = `Basic ${token}`;
    const url = `${this.authServiceRoot}/user/resetPassword`;
    return this.networkService.post(url, { username: email }, headers);
  };

  validateResetPasswordToken = (email, ptoken) => {
    // client
    const headers = {};

    //if (!this.authenticated()) {
    const token = window.btoa(this.clientId + ":" + this.clientSecret);
    headers.Authorization = `Basic ${token}`;
    //}

    const url = `${this.authServiceRoot}/user/validatePasswordToken`;

    return this.networkService
      .post(url, { username: email, password: ptoken }, headers)
      .then((response) => {
        const accessToken = response["access_token"];
        this._storage.setItem("access_token", accessToken);
        this.networkService.setAccessToken(accessToken);
        this._storage.setItem("ptoken", ptoken);
        this._storage.setItem("email", email);
        return Promise.resolve(response);
      })
      .catch((error) => {
        this._storage.removeItem("ptoken");
        this._storage.removeItem("email");
      });
  };

  getStoredValue = (key) => {
    return this._storage.getItem(key);
  };

  updatePassword = (passwordRequest) => {
    const url = `${this.authServiceRoot}/user/password`;
    return this.networkService.patch(url, passwordRequest);
  };

  getCustomerVoucherActivation = () => {
    const url = `${this.authServiceRoot}/voucher/activation`;
    return this.networkService
      .get(url)
      .then((response) => {
        //console.log("getCustomerVoucherActivation - then");
        //console.log(response);
        if (response && response.code) {
          const voucherValue = `${response.appSiteName}|${response.code}`;
          this._storage.setItem("voucher", voucherValue);
        } else {
          this._storage.removeItem("voucher");
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        this._storage.removeItem("voucher");
        return Promise.reject(error);
      });
  };

  activateVoucherCode = (code) => {
    const url = `${this.authServiceRoot}/voucher/redeem`;
    let headers = null;
    if (!this.authenticated()) {
      const token = window.btoa(this.clientId + ":" + this.clientSecret);
      headers = {
        Authorization: `Basic ${token}`,
      };
    }

    return this.networkService
      .post(url, { code: code }, headers)
      .then((response) => {
        if (response) {
          this.processTokenResponse(response);
          return this.getCustomerVoucherActivation().then((response) => {
            this.triggerUserVoucher();
          });
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        if (
          error &&
          error.response &&
          error.response.code === "ALREADY_REDEEMED"
        ) {
          //alert("already redeemed");
          this.triggerUserSignIn();
          this.triggerUserVoucher();
          return Promise.resolve(null);
        }
        return Promise.reject(error);
      });
  };

  clearFullAccessCache = (userId = "") => {
    try {
      const endpoint = `${this.nutritionServiceRoot}/cache/evict/fullaccess/${userId}`;
      NetworkService.getInstance()
        .get(endpoint)
        .catch((ex) => {});
    } catch (ex) {}
  };

  clearEdgeAccessCache = (userId = "") => {
    try {
      const endpoint = `${this.nutritionServiceRoot}/cache/evict/edgeaccess/${userId}`;
      NetworkService.getInstance()
        .get(endpoint)
        .catch((ex) => {});
    } catch (ex) {}
  };

  clearUserVoucherAccessCache = (userId = "") => {
    try {
      const endpoint = `${this.nutritionServiceRoot}/cache/evict/user_voucher_access/${userId}`;
      return NetworkService.getInstance().get(endpoint);
    } catch (ex) {}
  };

  sendAccountDeleteRequest = () => {
    const url = `${this.authServiceRoot}/user/customer`;
    return this.networkService.delete(url);
  };
}
