import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled/macro";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { FilterContainer } from "../Search/Filters/ui/FilterContainer";
import RatingsFilter from "../Search/Filters/AdvancedSearchFilters/RatingsFilter";
import { FilterButtonsContainer } from "../Search/Filters/ui/FiltersButtons";
import AuthService from "../../../service/AuthService";

const DateInput = styled.input`
  border: 1px solid var(--primary-black);
  border-radius: 2px;

  &:focus-visible {
    outline: none;
    border: 1px solid var(--primary-blue);
  }
`;

const UPDATE_TYPE = [
  { value: "PUBLISHED", label: "Published" },
  {
    value: "CRITERIA_SCORE_NOT_CHANGED",
    label: "No Criteria or Score Changed",
  },
  { value: "CRITERIA_SCORE_CHANGED", label: "Criteria or Score Changed" },
  { value: "RATINGS_CHANGED", label: "Overall Rating Changed" },
  //{ value: "UPDATED", label: "Updated" },
];
const date = new Date();
const thirtyDaysAgo = date.setDate(date.getDate() - 30);
const maxDate = new Date().toISOString().split("T")[0];

const defaultStartDate = new Date(thirtyDaysAgo).toISOString().split("T")[0];
const defaultEndDate = new Date().toISOString().split("T")[0];

export default function RecentUpdatesFilters({
  selectedFilters,
  handleClear,
  handleClearAll,
  handleChange,
  handleDateChange,
  numberOfFilters,
  results,
  isPreviousData,
  isFiltersOpen,
  download,
}) {
  const principal = useSelector((state) => state.authentication?.principal);

  const [downloading, setDownloading] = useState(false);

  const downloadFile = (e) => {
    setDownloading(true);
    download(e).finally((res) => setDownloading(false));
  };

  const [validTypes, setValidTypes] = useState();

  useEffect(() => {
    const types = [];
    if (AuthService.getInstance().hasLabelAccess(principal)) {
      types.push({ value: "WEB", label: "Website" });
    }
    if (AuthService.getInstance().hasTVAccess(principal)) {
      types.push({ value: "TV", label: "TV" });
    }
    if (AuthService.getInstance().hasPodcastAccess(principal)) {
      types.push({ value: "PODCAST", label: "Podcast" });
    }

    setValidTypes(types);
  }, [principal]);

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        height: {
          duration: 0.4,
        },
        opacity: {
          duration: 0.25,
          delay: 0.15,
        },
      },
    },
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        height: {
          duration: 0.2,
        },
        opacity: {
          duration: 0.15,
        },
      },
    },
  };

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial={{
          height: 0,
          opacity: 0,
        }}
        animate={isFiltersOpen ? "open" : "closed"}
        variants={variants}
      >
        {validTypes ? (
          <FilterContainer
            filter={selectedFilters?.rating_type}
            toggleContainer={() => console.log("RatingsFilter toggleContainer")}
            handleClear={handleClear}
            // toggleContainer={handleUI}
            // isContainerOpen={openFilters.has("ratings")}
            isContainerOpen={true}
          >
            <RatingsFilter
              RATINGS={validTypes}
              onChange={handleChange}
              filter={selectedFilters?.rating_type}
              value={selectedFilters?.rating_type.value}
              id={selectedFilters?.rating_type.slug}
              name={selectedFilters?.rating_type.slug}
            />
          </FilterContainer>
        ) : (
          ""
        )}
        <FilterContainer
          filter={selectedFilters?.update_type}
          toggleContainer={() => console.log("RatingsFilter toggleContainer")}
          handleClear={handleClear}
          // toggleContainer={handleUI}
          // isContainerOpen={openFilters.has("ratings")}
          isContainerOpen={true}
        >
          <RatingsFilter
            RATINGS={UPDATE_TYPE}
            onChange={handleChange}
            filter={selectedFilters?.update_type}
            value={selectedFilters?.update_type.value}
            id={selectedFilters?.update_type.slug}
            name={selectedFilters?.update_type.slug}
          />
        </FilterContainer>
        <FilterContainer
          filter={selectedFilters?.start_date}
          toggleContainer={() => console.log("RatingsFilter toggleContainer")}
          handleClear={handleClear}
          // toggleContainer={handleUI}
          // isContainerOpen={openFilters.has("ratings")}
          isContainerOpen={true}
        >
          <DateInput
            type="date"
            value={selectedFilters?.start_date.value || defaultStartDate}
            id={selectedFilters?.start_date.slug}
            name={selectedFilters?.start_date.slug}
            min="2018-01-01"
            max={maxDate}
            onChange={handleDateChange}
          />
        </FilterContainer>
        <FilterContainer
          filter={selectedFilters?.end_date}
          toggleContainer={() => console.log("RatingsFilter toggleContainer")}
          handleClear={handleClear}
          // toggleContainer={handleUI}
          // isContainerOpen={openFilters.has("ratings")}
          isContainerOpen={true}
        >
          <DateInput
            type="date"
            value={selectedFilters?.end_date.value || defaultEndDate}
            id={selectedFilters?.end_date.slug}
            name={selectedFilters?.end_date.slug}
            min={selectedFilters?.start_date.value || defaultStartDate}
            max={maxDate}
            onChange={handleDateChange}
          />
        </FilterContainer>

        <FilterButtonsContainer
          principal={principal}
          handleClear={handleClearAll}
          hasFiltersSelected={numberOfFilters > 0}
          results={results}
          isLoading={isPreviousData}
          isWidget={true}
          onClick={downloadFile}
          downloading={downloading}
        />
      </m.div>
    </LazyMotion>
  );
}
