import styled from "@emotion/styled/macro";
import MythCategoryChooser from "../../../Settings/MythCategoryChooser";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 90%;
  column-gap: 100px;
  margin-left: 10px;
`;

function MythCategoriesFilter({ value, onChange, principal }) {
  return (
    <Wrapper>
      <MythCategoryChooser
        mythSettings={value}
        handleChange={onChange}
        principal={principal}
      />
    </Wrapper>
  );
}

export default MythCategoriesFilter;
