import { useState } from "react";
import { useDispatch } from "react-redux";
import SiteModule from "../../common/SiteModule";
import { AdvancedSearchWrapper } from "../Filters/ui/AdvancedSearchStyles";

import { FilterContainer } from "../Filters/ui/FilterContainer";
import { SortByFilter, SortOrderFilter } from "./SortFilters";
import { FilterButtonsContainer } from "../Filters/ui/FiltersButtons";
import { setSelectedFilters } from "../../../../_actions";

const SORT_BY_OPTIONS = [
  {
    type: "",
    value: "",
    label: "",
  },
  {
    type: "sortBy",
    value: "metadata.date_first_appearance.body",
    label: "First Appeared",
  },
  {
    type: "sortBy",
    value: "labels.firstPublishedDate",
    label: "First Cataloged",
  },
  { type: "sortBy", value: "labels.lastPublishedDate", label: "Last Updated" },
];

const SORT_ORDER_OPTIONS = [
  { type: "sortBy", value: "desc", label: "Newest to Oldest" },
  { type: "sortBy", value: "asc", label: "Oldest to Newest" },
];

// ----------------------------

function MythSort({
  clear,
  clearAll,
  toggleModule,
  isOpen,
  principal,
  filters,
  searchContext,
  results,
  loading,
  numberOfFilters,
}) {
  const [openFilters, setOpenFilters] = useState(new Set());
  const dispatch = useDispatch();

  const handleUI = (name) => {
    const currentOpenFilters = new Set();
    setOpenFilters(currentOpenFilters);

    if (openFilters.has(name)) {
      return;
    }

    currentOpenFilters.add(name);
    setOpenFilters(currentOpenFilters);
  };

  const handleChange = (selectData, action) => {
    const name = action.name;
    const selectedValue = selectData.value;

    dispatch(setSelectedFilters(name, selectedValue, searchContext, principal));
  };

  return (
    <AdvancedSearchWrapper
      isOpen={isOpen}
      onDismiss={toggleModule}
      aria-label="Sort Options"
      type="sort"
    >
      <SiteModule
        name="Sort Options"
        search={true}
        context="filters"
        isOpen={isOpen}
        handleClose={toggleModule}
        aria-label="Sort Options"
      >
        <FilterContainer
          filter={filters?.sortBy}
          handleClear={clear}
          toggleContainer={handleUI}
          isContainerOpen={openFilters.has("sortBy")}
        >
          <SortByFilter
            onChange={handleChange}
            value={filters.sortBy}
            options={SORT_BY_OPTIONS}
            defaultValue={""}
          />
        </FilterContainer>
        <FilterContainer
          filter={filters?.sortOrder}
          handleClear={clear}
          toggleContainer={handleUI}
          isContainerOpen={openFilters.has("sortOrder")}
        >
          <SortOrderFilter
            onChange={handleChange}
            value={filters.sortOrder}
            options={SORT_ORDER_OPTIONS}
            defaultValue={"desc"}
            isDisabled={!filters.sortBy.value}
          />
        </FilterContainer>
        <FilterButtonsContainer
          principal={principal}
          handleClear={clearAll}
          hasFiltersSelected={numberOfFilters > 0}
          results={results}
          isLoading={loading}
          searchContext={{ value: "MYTH" }}
          filterContext="sort"
          close={toggleModule}
        />
      </SiteModule>
    </AdvancedSearchWrapper>
  );
}

export default MythSort;
