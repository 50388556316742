import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FormattedMessage } from "react-intl";

import UpdateAccountName from "./UpdateAccountName";
import AccountService from "../../service/AccountService";
import AuthService from "../../service/AuthService";
import { getLocale } from "../../common/_localeMessages";
import {
  AccountDetails,
  AccountDetailsHeader,
  AccountSection,
  AccountSectionHeader,
  AccountSectionWrapper,
} from "./AccountStyles";
import { AccountButton } from "../FormElements/Button";
import { LinkButtonAccount } from "../Subscription/SignupStyles";
import Membership from "./Membership";
import LoadingIndicator from "../../common/LoadingIndicator";
import formattedDate from "../Partners/Helpers/formattedDate";

const AccountSettings = ({ elementRef, secondaryRef }) => {
  const principal = useSelector((state) => state.authentication.principal);
  const user = useSelector((state) => state.authentication.user);

  const [subscriptions, setSubscriptions] = useState([]);
  const [activation, setActivation] = useState(false);
  const [error, setError] = useState(false);

  const [isExpiring, setIsExpiring] = useState(false);

  const [editAccountName, setEditAccountName] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userLoading, setULoading] = useState(true);

  useEffect(() => {
    if (user) {
      setULoading(false);
    }
  }, [user]);

  useEffect(() => {
    getSubscriptions();
    getActivation();
  }, []);

  const getActivation = () => {
    AuthService.getInstance()
      .getCustomerVoucherActivation()
      .then((response) => {
        setActivation(response);

        // evict full access cache in nutrition side to sync.
        AuthService.getInstance().clearFullAccessCache(principal.id);
      })
      .catch((error) => {
        setActivation(false);
      });
  };

  const getSubscriptions = () => {
    AccountService.getInstance()
      .getSubscriptions(true)
      .then((subscriptions) => {
        setSubscriptions(subscriptions);
        if (subscriptions && subscriptions.length > 0) {
          subscriptions.map((subscription) => {
            if (
              subscription.status === "past_due" ||
              subscription === "incomplete"
            ) {
              setError(true);
            }
            return null;
          });

          // evict full access cache in nutrition side to sync.
          AuthService.getInstance().clearFullAccessCache(principal.id);

          // setHaveActiveSubModalOpen(false);
        }
      })
      .catch((error) => {
        setError(true);
      });
  };

  const handleCancel = (e, subscription) => {
    e.preventDefault();
    setLoading(true);
    AccountService.getInstance()
      .cancelSubscription(subscription)
      .then((response) => {
        getSubscriptions();
        setIsExpiring(true);
        setLoading(false);
      });
  };

  const validUntilDate = formattedDate(activation.validUntil, getLocale(), {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <>
      <div ref={elementRef} id="account">
        <AccountSectionHeader>
          <FormattedMessage id="template.account" defaultMessage="Account" />
        </AccountSectionHeader>
        {error ? (
          <div className="checkout__error">
            <FormattedMessage
              id="message.update_payment"
              defaultMessage="Please update your <paymentlink>Payment Method</paymentlink>"
              values={{
                paymentlink: (...chunks) => (
                  <a href="/account/paymentmethod">{chunks}</a>
                ),
              }}
            />
          </div>
        ) : (
          ""
        )}
        <AccountSection>
          {userLoading && <LoadingIndicator />}
          {editAccountName ? (
            <UpdateAccountName
              onDismiss={() => setEditAccountName(false)}
              firstName={user?.firstName}
              lastName={user?.lastName}
            />
          ) : (
            <>
              <AccountSectionWrapper>
                <AccountDetailsHeader>
                  <FormattedMessage id="template.name" defaultMessage="Name" />
                </AccountDetailsHeader>
                <AccountButton onClick={() => setEditAccountName(true)}>
                  {/* <FormattedMessage id="template.change" defaultMessage="Change" /> */}
                  Edit {/* Need translation of Edit */}
                </AccountButton>
              </AccountSectionWrapper>
              <AccountDetails>
                {user?.firstName} {user?.lastName}
              </AccountDetails>
            </>
          )}
        </AccountSection>
        <AccountSection>
          <AccountSectionWrapper>
            <AccountDetailsHeader>
              <FormattedMessage id="template.email" defaultMessage="Email" />
            </AccountDetailsHeader>
          </AccountSectionWrapper>
          <AccountDetails>{user?.email}</AccountDetails>
        </AccountSection>
        <AccountSection>
          <AccountSectionWrapper>
            <AccountDetailsHeader>
              <FormattedMessage
                id="template.password"
                defaultMessage="Password"
              />
            </AccountDetailsHeader>
            <LinkButtonAccount
              to="/forgotPassword"
              className="account-information__button"
            >
              <FormattedMessage
                id="template.reset_password"
                defaultMessage="Reset Password"
              />
            </LinkButtonAccount>
          </AccountSectionWrapper>
          <AccountDetails>•••••••</AccountDetails>
        </AccountSection>
        <AccountSection>
          <AccountSectionWrapper>
            <AccountDetailsHeader>
              <FormattedMessage
                id="template.country"
                defaultMessage="Country"
              />
            </AccountDetailsHeader>
          </AccountSectionWrapper>
          <AccountDetails>{user?.country}</AccountDetails>
        </AccountSection>
      </div>
      <Membership
        user={user}
        subscriptions={subscriptions}
        activation={activation}
        elementRef={secondaryRef}
        validUntilDate={validUntilDate}
        onCancel={handleCancel}
        isExpiring={isExpiring}
        loading={loading}
      />
    </>
  );
};

export default AccountSettings;
