import styled from "@emotion/styled";
import LabelTypeBadge from "../common/LabelTypeBadge";
import formattedDate from "./formattedDate";

const Note = styled.span`
  white-space: nowrap;
`;

const updateTypeMap = {
  UNPUBLISHED: (lh) => "Taken down",
  PUBLISHED: (lh) => "Published",
  CRITERIA_SCORE_NOT_CHANGED: (lh) => "No Criteria or Score changed",
  RATINGS_CHANGED: (lh) => "Overall rating changed",
  CRITERIA_SCORE_CHANGED: (lh) => "Criteria or score changed",
  UPDATED: (lh) => "Updated",
  IDENTIFIER_CHANGED: (lh, target) =>
    `${
      target === "home"
        ? "Domain changed"
        : `Domain changed from ${lh.oldIdentifier} to ${lh.identifier}.`
    }`,
};

export const formatLabelHistoryNote = (labelHistory, label, target) => {
  // console.log(label);
  // if there is no oldRank, it is new
  let note;
  if (labelHistory.updateType) {
    note = updateTypeMap[labelHistory.updateType](labelHistory, target);
  } else {
    //deprecated
    if (labelHistory.oldRank) {
      if (labelHistory.note === "domain changed") {
        note = `Domain changed from ${labelHistory.oldIdentifier} to ${labelHistory.identifier}.`;
      } else if (
        labelHistory.publisherType === "TV_NETWORK" &&
        labelHistory.newRank === "P"
      ) {
        note = "Updated";
      } else {
        const rankChanged = labelHistory.oldRank !== labelHistory.newRank;
        const scoreChanged = labelHistory.oldScore !== labelHistory.newScore;

        if (labelHistory.newRank === "TK") {
          note = "Unpublished";
        } else if (
          !rankChanged &&
          (labelHistory.criteriaChanged || scoreChanged)
        ) {
          note = "Criteria or score changed";
        } else if (rankChanged) {
          note = "Overall rating changed";
        } else {
          note = "No Criteria or Score changed";
        }
      }
    } else {
      note = "Published";
    }
  }
  return <Note>{note}</Note>;
};

export const hasCriteria = (rank) => {
  /**
   *  {data.cell.row.original.oldRank === "T" ||
   *               data.cell.row.original.oldRank === "N" ||
   *               data.cell.row.original.oldRank === "M" ||
   *               data.cell.row.original.oldRank === "A" ||
   *               data.cell.row.original.oldRank === "B" ||
   *               data.cell.row.original.oldRank === "C" ||
   *               data.cell.row.original.oldRank === "D" ||
   *               data.cell.row.original.oldRank === "F"
   */
  return (
    rank === "T" ||
    rank === "N" ||
    rank === "M" ||
    rank === "A" ||
    rank === "B" ||
    rank === "C" ||
    rank === "D" ||
    rank === "F"
  );
};

export const hasNoCriteria = (rank) => {
  /**
   *  {data.cell.row.original.oldRank === "T" ||
   *               data.cell.row.original.oldRank === "N" ||
   *               data.cell.row.original.oldRank === "M" ||
   *               data.cell.row.original.oldRank === "A" ||
   *               data.cell.row.original.oldRank === "B" ||
   *               data.cell.row.original.oldRank === "C" ||
   *               data.cell.row.original.oldRank === "D" ||
   *               data.cell.row.original.oldRank === "F"
   */
  return (
    rank === "FL" ||
    rank === "P" ||
    rank === "S" ||
    rank === "AI" ||
    rank === "L"
  );
};

export const scoreFormatter = (
  oldRank,
  oldScore,
  newRank,
  newScore,
  network,
  publisherType
) => {
  let classNames = ["score"];
  if (hasNoCriteria(newRank)) {
    return (
      <LabelTypeBadge
        publisherType={publisherType}
        rating={newRank}
        size="small"
        type="full"
      />
    );
  }

  if ((oldScore === 0 || oldScore) && newScore - oldScore > 0) {
    classNames.push("score--increase");
  } else if ((oldScore === 0 || oldScore) && newScore - oldScore < 0) {
    classNames.push("score--decrease");
  }
  if (
    newRank === "T" ||
    newRank === "A" ||
    newRank === "B" ||
    newRank === "C"
  ) {
    if (network === "PODCAST" || network === "TV") {
      classNames.push("score--green");
    } else {
      classNames.push("score--blue");
    }
  }
  if (newRank === "N" || newRank === "D" || newRank === "F") {
    if (network === "PODCAST" || network === "TV") {
      classNames.push("score--red");
    } else {
      classNames.push("score--blue");
    }
  }

  if (newRank === "M") {
    classNames.push("score--orange");
  }
  return <span className={classNames.join(" ")}>{newScore}</span>;
};

export const dateFormatter = (value) => {
  if (!value) return null;
  const dateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    //timeZone: "UTC",
  };

  const timeOptions = {
    year: undefined,
    month: undefined,
    day: undefined,
    hour: "numeric",
    minute: "numeric",
    //timeZone: "UTC",
  };

  const date = new Date(value);
  const americanDate = formattedDate(date, "en-US", dateOptions);
  const americanTime = formattedDate(date, "en-US", timeOptions);
  return (
    <div className="date">
      <span className="date--month-day">{americanDate}</span>
      <span className="date--time">{americanTime}</span>
    </div>
  );
};

export const localeValueTransformer = (original) => {
  return original.replace("_", "-").replace("-ALL", "");
};
