import { Link } from "react-router-dom";
import { useCardLink } from "./useCardLink";
import {
  LabelDescriptionStyling,
  LabelMetaAndScoreContainer,
  LabelNameStyling,
  MetaDataContent,
  MetadataWrapper,
  SearchResultLink,
  SearchResultStyles,
  SearchResultWrapper,
} from "./SearchResultsStyles";
import ReportMarker from "../common/ReportMarker";
import React from "react";
import { LocaleValue } from "../../../common/Helper";
import { getLocale } from "../../../common/_localeMessages";

export default function ReportPublishedSearchResult({
  report,
  index,
  localeLabels,
}) {
  const [cardRef, linkRef] = useCardLink();
  if (report) {
    return (
      <SearchResultWrapper key={`result-${index}`} ref={cardRef}>
        <SearchResultStyles>
          <div className="labelInfo">
            <LabelNameStyling>
              <ReportMarker SearchResult />
              <SearchResultLink
                to={`/partner/report/profile/${report.profileId}`}
                dangerouslySetInnerHTML={{
                  __html: report.title || report.name,
                }}
                ref={linkRef}
              />
            </LabelNameStyling>
            <LabelDescriptionStyling
              dangerouslySetInnerHTML={{
                __html: report.topline,
              }}
            />
          </div>
          <LabelMetaAndScoreContainer myth>
            <MetadataWrapper myth>
              {report?.firstPublishedDate ? (
                <>
                  <h4>Published</h4>
                  <span className="appeared_date">
                    {new Date(
                      report.firstPublishedDate * 1000 // Convert from seconds to milliseconds for javascript Date object
                    ).toLocaleDateString(getLocale(), {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })}
                  </span>
                </>
              ) : (
                ""
              )}
              <MetaDataContent>
                <h4>Locale</h4>
                <span className="locale">
                  {localeLabels.map((localeLabel) => (
                    <div key={localeLabel.id}>
                      <Link to={`/partner/report/${localeLabel.id}`}>
                        {LocaleValue(localeLabel.country, localeLabel.language)}
                      </Link>
                    </div>
                  ))}
                </span>
              </MetaDataContent>
            </MetadataWrapper>
          </LabelMetaAndScoreContainer>
        </SearchResultStyles>
      </SearchResultWrapper>
    );
  }
  return "";
}
