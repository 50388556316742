import { useQuery } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";

export function useReports(reportId, profileId) {
  const {
    data: report,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["report", reportId, profileId],
    queryFn: () => getData(reportId, profileId),
  });

  return {
    report,
    isLoading,
    isError,
  };
}

async function getData(reportId, profileId) {
  const contentServiceInstance = ContentService.getInstance();

  let getReport;
  if (profileId) {
    getReport = await contentServiceInstance.getReportByProfileId(profileId);
  } else {
    getReport = await contentServiceInstance.getReport(reportId);
  }

  return getReport;
}
