import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";
import MythMarker from "../common/MythMarker";
import { PublishedDate } from "../Myths/MythStyles";
import { getLocale } from "../../../common/_localeMessages";
import formattedDate from "../Helpers/formattedDate";
import MythExampleLinkExcerpt from "../Myths/MythExampleLinkExcerpt";
import { FlagList, FlagListItem } from "../Search/SearchResultsStyles";

const MythExampleLinksList = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  margin-bottom: 43px;
`;

const MythExampleLinksListItem = styled.li`
  display: flex;
  flex-direction: row;
`;

const MythTitleStyles = styled.h3`
  font-size: 22px;
  line-height: 27px;
  margin-bottom: 5px;
  a {
    text-decoration: none;
    color: #3355dd;
  }
`;

const MythList = styled.ul`
  /* list-style-type: none; */
  color: #3355dd;
  padding: 5px 0 0 20px;
`;

const MythSectionListItemStyles = styled.li`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
  }

  &:last-of-type {
    margin-bottom: 50px;
  }

  a {
    text-decoration: none;
    color: #3355dd;
    word-break: break-all;
  }
`;

const AdditionalInfo = styled.div``;

const AdditionalMetadata = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ExampleMetadata = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: -8px;
`;

const ArchiveLink = styled.a`
  background-color: rgba(0, 0, 0, 0.08);
  height: 26px;
  padding: 4px 8px;
  color: #000000 !important;
  border: 0.5px solid #cccccc;
  border-radius: 2px;
  font-family: Sora;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
`;

const List = styled.ul`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding: 0 0 0 18px;
  list-style: none;
`;

const LabelMythExampleLinks = ({ labelMythExampleLinks }) => {
  const hasMyths = labelMythExampleLinks
    ? labelMythExampleLinks.length > 0
    : false;

  return hasMyths ? (
    labelMythExampleLinks.map((labelMythExampleLinksEntity) => {
      const mythExampleLinkList = labelMythExampleLinksEntity.mythExampleLinks;
      if (mythExampleLinkList && mythExampleLinkList.length > 0) {
        const riskOfHarm =
          mythExampleLinkList[0].myth?.metadata?.harm_risk?.body;
        const showRiskOfHarm =
          riskOfHarm && (riskOfHarm === "High" || riskOfHarm === "Highest");

        return (
          <MythExampleLinksList key={mythExampleLinkList[0].myth.id}>
            <MythExampleLinksListItem>
              <MythMarker developing={mythExampleLinkList[0].myth.developing} />
              <div>
                <MythTitleStyles>
                  <Link
                    to={`/partner/myth/${mythExampleLinkList[0].myth.id}`}
                    dangerouslySetInnerHTML={{
                      __html: mythExampleLinkList[0].myth.writeup?.name?.body,
                    }}
                  />
                </MythTitleStyles>
                {showRiskOfHarm && (
                  <List>
                    <li>
                      Risk of Harm:{" "}
                      <span style={{ textTransform: "uppercase" }}>
                        {riskOfHarm}
                      </span>
                    </li>
                  </List>
                )}

                <MythList>
                  {mythExampleLinkList.map((mythExampleLink, index) => {
                    return (
                      <MythSectionListItemStyles
                        key={`${mythExampleLink.link}-${index}`}
                      >
                        <div>
                          <a
                            href={mythExampleLink.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {mythExampleLink.link}
                          </a>
                        </div>

                        <AdditionalInfo>
                          {mythExampleLink.excerpt ? (
                            <MythExampleLinkExcerpt
                              excerpt={mythExampleLink.excerpt}
                            />
                          ) : null}
                          <AdditionalMetadata>
                            <div>
                              {mythExampleLink.stateSponsored ? (
                                <ExampleMetadata>
                                  <svg
                                    width="30px"
                                    height="30px"
                                    viewBox="0 0 60 60"
                                    version="1.1"
                                  >
                                    <title>Flag</title>
                                    <g
                                      id="Symbols"
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="#000"
                                      fillRule="evenodd"
                                    >
                                      <g id="ii/system/flag" fill="%23000000">
                                        <path
                                          d="M20,15 L44,27 L23,37.5 L23,47 L20,47 L20,15 Z"
                                          id="Combined-Shape"
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                  <div>State-Controlled Source</div>
                                </ExampleMetadata>
                              ) : null}
                              {mythExampleLink.dateTime && (
                                <PublishedDate>
                                  {formattedDate(
                                    mythExampleLink.dateTime,
                                    getLocale()
                                  )}
                                </PublishedDate>
                              )}
                            </div>
                            {mythExampleLink.archiveLink ? (
                              <ArchiveLink
                                href={mythExampleLink.archiveLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Archived
                              </ArchiveLink>
                            ) : null}
                          </AdditionalMetadata>
                        </AdditionalInfo>
                      </MythSectionListItemStyles>
                    );
                  })}
                </MythList>
              </div>
            </MythExampleLinksListItem>
          </MythExampleLinksList>
        );
      }
      return null;
    })
  ) : (
    <p>This label has no associated false narratives.</p>
  );
};

export default LabelMythExampleLinks;
