import NetworkService from "./NetworkService";

const ContentService = (() => {
  let instance;

  function createInstance() {
    return new ContentServiceInstance();
  }

  return {
    getInstance: function () {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    },
  };
})();

export default ContentService;

class ContentServiceInstance {
  networkService = {};
  resourceServiceRoot = "";

  constructor() {
    this.networkService = NetworkService.getInstance();
    this.resourceServiceRoot = "";
  }

  updateConfig(config) {
    if (config) {
      this.resourceServiceRoot = config.resourceServiceRoot;
    }
  }

  // search
  search = (term, filters, sort = {}, page, sizePerPage) => {
    const filterList = [{ key: "query", filterVal: term ? term : "" }];

    if (filters) {
      Object.keys(filters).forEach((k) => {
        if (filters[k]) {
          filters[k].key = k;
          filterList.push(filters[k]);
        }
      });
    }

    // console.log(filterList);
    const filterInfo = {
      page: page,
      size: sizePerPage,
      filters: filterList,
      sort,
    };

    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/search`;

    return this.networkService
      .post(endpoint, filterInfo, headers)
      .then((results) => {
        // save the search parameters for reference
        this.saveSearchHistory(term);
        return Promise.resolve(results);
      })
      .catch((error) => {
        return Promise.reject({
          response: error,
        });
      });
  };

  searchDownload = (term, filters, sort = {}, searchContext, filename = "") => {
    const filterList = [{ key: "query", filterVal: term ? term : "" }];

    if (filters) {
      Object.keys(filters).forEach((k) => {
        if (filters[k]) {
          filters[k].key = k;
          filterList.push(filters[k]);
        }
      });
    }

    // console.log(filterList);
    const filterInfo = {
      page: 0,
      size: 500,
      filters: filterList,
      sort,
    };

    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/search/${searchContext}/download`;
    const now = new Date();
    const downloadFileName = filename ? filename : searchContext + ".csv";

    return this.networkService
      .downloadByPost(endpoint, filterInfo)
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = `${downloadFileName}`;
        a.href = href;
        a.click();
      })
      .catch((error) => {
        return Promise.reject({
          response: error,
        });
      });
  };

  getOwnerValues = (query = "") => {
    let headers = {};
    const endpoint = `${
      this.resourceServiceRoot
    }/search/owners?query=${encodeURIComponent(query)}`;
    return this.networkService.get(endpoint, headers);
  };

  getTVNetworkValues = (query = "") => {
    let headers = {};
    const endpoint = `${
      this.resourceServiceRoot
    }/search/tv_network?query=${encodeURIComponent(query)}`;
    return this.networkService.get(endpoint, headers);
  };

  // store search history
  saveSearchHistory = (term) => {
    try {
      if (term) {
        term = term.trim();
      }
      if (term) {
        const myStorage = window.localStorage;
        const searchHistory = this.getSearchHistory();
        const result = searchHistory.filter((word) => word !== term);
        while (result.length > 10) {
          result.pop();
        }
        result.unshift(term);
        myStorage.setItem("search_history", JSON.stringify(result));
      }
    } catch (e) {}
  };

  // retrieve search history
  getSearchHistory = () => {
    const myStorage = window.localStorage;
    const searchHistory = myStorage.getItem("search_history");
    return JSON.parse(searchHistory) || [];
  };

  clearSearchHistory = () => {
    const myStorage = window.localStorage;
    myStorage.removeItem("search_history");
  };

  // get label

  getLabelByProfile = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/profile/${uuid}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabel = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/${uuid}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabelLocalesByProfile = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/profile/${uuid}/locales`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabelLocales = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/publisher/${uuid}/locales`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getAssociatedDomains = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/profile/${uuid}/associated`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getConnectedMedia = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/profile/${uuid}/connected`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // get label history
  getLabelHistory = (labelId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/${labelId}/history`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // get label history by Profile Id
  getProfileLabelHistory = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/profile/${profileId}/history`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // get recent updated labels
  getRecentlyUpdatedLabels = (pageSize, pageIndex) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/recentupdates`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getRecentlyUpdatedLabelPage = (
    page,
    sizePerPage,
    sortField,
    sortOrder,
    filters
  ) => {
    let sort = {};
    if (sortField) {
      sort[sortField] = sortOrder;
    }

    const filterList = [];

    if (filters) {
      Object.keys(filters).forEach((k) => {
        let filter = Object.assign({}, filters[k]);
        if (filter) {
          filter.key = k;
          filterList.push(filter);
        }
      });
    }
    const filterInfo = {
      page: page - 1,
      size: sizePerPage,
      filters: filterList,
      sort: sort,
    };

    const endpoint = `${this.resourceServiceRoot}/label/recentupdates`;
    return this.networkService.post(endpoint, filterInfo).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // get myth
  getMythByProfileId = (id) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/mythprofile/${id}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getMyth = (id) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/${id}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getMythByMythMainId = (id) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/developing_narrative/mythmain/${id}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabelMyths = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/profile/${profileId}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabelMythsByMyth = (mythId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/${mythId}/profiles`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  countLabelMyths = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/profile/${profileId}/count`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getMythLocalesByProfile = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/mythprofile/${uuid}/locales`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getMythLocales = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/mythmain/${uuid}/locales`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // following
  follow = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow/${profileId}`;
    return this.networkService.post(endpoint, {}, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  unfollow = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow/${profileId}`;
    return this.networkService.delete(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  isFollowing = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow/${profileId}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  followLabel = (labelId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow/label/${labelId}`;
    return this.networkService.post(endpoint, {}, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  unfollowLabel = (labelId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow/label/${labelId}`;
    return this.networkService.delete(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  isFollowingLabel = (labelId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow/label/${labelId}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  listFollows = () => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/follow`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  listFollowsPage = (page, sizePerPage, sortField, sortOrder, filters) => {
    let sort = {};
    if (sortField) {
      sort[sortField] = sortOrder;
    }

    const filterList = [];

    const filterInfo = {
      page: page - 1,
      size: sizePerPage,
      filters: filterList,
      sort: sort,
    };

    const endpoint = `${this.resourceServiceRoot}/follow`;
    return this.networkService.post(endpoint, filterInfo).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // request
  sendRequest = (request) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/request`;
    return this.networkService
      .post(endpoint, request, headers)
      .catch((error) => {
        return Promise.reject({
          response: error,
        });
      });
  };

  checkDomain = (url) => {
    let headers = {};
    const endpoint = `${
      this.resourceServiceRoot
    }/label/check?url=${encodeURIComponent(url)}`;
    return this.networkService.get(endpoint, headers);
  };

  // downloadLabel
  downloadLabel = () => {
    const endpoint = `${this.resourceServiceRoot}/label/download`;
    const dateString = new Date().toISOString().split("T")[0].replace(/-/g, "");

    return this.download(endpoint, `label-full-metadata-${dateString}.csv`);
  };

  downloadTVLabel = () => {
    const endpoint = `${this.resourceServiceRoot}/label/download/tv`;
    const dateString = new Date().toISOString().split("T")[0].replace(/-/g, "");

    return this.download(endpoint, `tv-full-metadata-${dateString}.csv`);
  };

  // download
  downloadMyth = () => {
    const endpoint = `${this.resourceServiceRoot}/myth/download`;
    const dateString = new Date().toISOString().split("T")[0].replace(/-/g, "");

    return this.download(endpoint, `myths-${dateString}.csv`);
  };

  downloadPodcastLabel = () => {
    //const endpoint = `${this.resourceServiceRoot}/label/download/podcast`;
    const dateString = new Date().toISOString().split("T")[0].replace(/-/g, "");

    // return this.download(endpoint, `podcast-metadata-${dateString}.csv`);

    const filters = {
      type: {
        filterVal: "PODCAST",
      },
    };
    return this.searchDownload(
      "",
      filters,
      {},
      "PODCAST",
      `podcast-metadata-${dateString}.csv`
    );
  };

  downloadRecentUpdates = (data) => {
    const filterList = [];

    if (data) {
      Object.keys(data).forEach((k) => {
        if (data[k]) {
          data[k].key = k;
          filterList.push(data[k]);
        }
      });
    }

    // console.log(filterList);
    const filterInfo = {
      filters: filterList,
    };

    const endpoint = `${this.resourceServiceRoot}/label/recentupdates/download`;
    const dateString = new Date().toISOString().split("T")[0].replace(/-/g, "");

    return this.networkService
      .downloadByPost(endpoint, filterInfo)
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = `recent-updates-${dateString}.csv`;
        a.href = href;
        a.click();
      })
      .catch((error) => {
        return Promise.reject({
          response: error,
        });
      });
  };

  download = (endpoint, filename) => {
    return this.networkService
      .download(endpoint)
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = filename;
        a.href = href;
        a.click();
      })
      .catch((error) => {
        return Promise.reject({
          response: error,
        });
      });
  };

  getChildLabels = (labelId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/${labelId}/child`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getParentLabels = (labelId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/label/${labelId}/parent`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabelMythExampleLinks = (profileId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/examplelink/profile/${profileId}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getLabelMythExampleLinksByMyth = (mythId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/examplelink/mythprofile/${mythId}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getMythMediaByMyth = (mythId) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/myth/mythprofile/${mythId}/media`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getRecentContentsPage = (
    page,
    sizePerPage,
    sortField,
    sortOrder,
    filters,
    id
  ) => {
    let sort = {};
    if (sortField) {
      sort[sortField] = sortOrder;
    }

    const filterList = [];

    const filterInfo = {
      page: page - 1,
      size: sizePerPage,
      filters: filterList,
      sort: sort,
    };

    const endpoint = `${this.resourceServiceRoot}/${id}/recent`;
    return this.networkService.post(endpoint, filterInfo).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getDistinctiveMythMetadataValue = (key, query) => {
    const endpoint = `${this.resourceServiceRoot}/myth/metadata/${key}?query=${query}`;
    return this.networkService.get(endpoint).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getDistinctiveReportMetadataValue = (key, query) => {
    const endpoint = `${this.resourceServiceRoot}/report/metadata/${key}?query=${query}`;
    return this.networkService.get(endpoint).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getDistinctiveCombinedMetadataValue = (key, query) => {
    const endpoint = `${this.resourceServiceRoot}/content/combined/metadata/${key}?query=${query}`;
    return this.networkService.get(endpoint).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  // get report
  getReportByProfileId = (id) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/report/reportprofile/${id}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getReport = (id) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/report/${id}`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getReportLocalesByProfile = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/report/reportprofile/${uuid}/locales`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getReportLocales = (uuid) => {
    let headers = {};
    const endpoint = `${this.resourceServiceRoot}/report/reportmain/${uuid}/locales`;
    return this.networkService.get(endpoint, headers).catch((error) => {
      return Promise.reject({
        response: error,
      });
    });
  };

  getMedia = async (fileId) => {
    const endpoint = `${this.resourceServiceRoot}/media/${fileId}`;
    return await this.networkService.get(endpoint);
  };

  downloadMediaUrl = (fileId, mediaToken) => {
    return (
      `${this.resourceServiceRoot}/media/${fileId}/download` +
      `?seed=${encodeURIComponent(mediaToken?.seed)}&token=${encodeURIComponent(
        mediaToken?.token
      )}`
    );
  };

  downloadMedia = async (fileId, mediaToken) => {
    const endpoint = this.downloadMediaUrl(fileId, mediaToken);
    return await this.networkService.download(endpoint);
  };

  getMediaStreamingS3Url = (mediaId, location, mediaToken) => {
    return (
      `${this.resourceServiceRoot}/media/${mediaId}/stream/s3` +
      `?seed=${encodeURIComponent(mediaToken?.seed)}&token=${encodeURIComponent(
        mediaToken?.token
      )}` +
      `&location=${encodeURIComponent(location)}`
    );
  };

  getMythMediaToken = async (mythProfileId, mediaId) => {
    const endpoint = `${this.resourceServiceRoot}/myth/mythprofile/${mythProfileId}/media/${mediaId}/token`;
    return await this.networkService.get(endpoint);
  };
}
