import { useState } from "react";

import SiteModule from "../../common/SiteModule";
import { AdvancedSearchWrapper } from "./ui/AdvancedSearchStyles";
import { FilterButtonsContainer } from "./ui/FiltersButtons";
import { useDispatch, useSelector } from "react-redux";
import {
  setCriteriaFilters,
  setScoreFilters,
  setScoreType,
  setSearchPage,
  setSelectedFilters,
} from "../../../../_actions";
import WebAdvancedSearch from "./WebAdvancedSearch";
import TVAdvancedSearch from "./TVAdvancedSearch";
import PodcastAdvancedSearch from "./PodcastAdvancedSearch";
import MythAdvancedSearch from "./MythAdvancedSearch";
import ReportAdvancedSearch from "./ReportAdvancedSearch";

export default function AdvancedSearch({
  principal,
  isOpen,
  toggleAdvancedFiltersModule,
  advanced,
  clear,
  clearAll,
  numberOfFilters,
  results,
  loading,
  searchContext,
  openSecondModal,
}) {
  const dispatch = useDispatch();
  const selectedFilters = useSelector(
    (state) => state.searchFilter.selectedFilters
  );

  const [openFilters, setOpenFilters] = useState(new Set());

  const handleUI = (name) => {
    const currentOpenFilters = new Set();
    setOpenFilters(currentOpenFilters);

    if (openFilters.has(name)) {
      return;
    }

    currentOpenFilters.add(name);
    setOpenFilters(currentOpenFilters);
  };

  const handleToggleChange = (name, val) => {
    dispatch(setSearchPage(0));
    dispatch(setSelectedFilters(name, val, searchContext, principal));
  };

  const handleSelectChange = (selectData, action) => {
    dispatch(setSearchPage(0));
    dispatch(
      setSelectedFilters(action.name, selectData, searchContext, principal)
    );
  };

  const handleTextChange = (e) => {
    // currently only used for owner filter
    const value = e.target.value;
    const name = e.target.name;
    dispatch(setSearchPage(0));
    dispatch(setSelectedFilters(name, value, searchContext, principal));
  };

  // handle checkbox and radio button changes
  const handleSelectAndRadioChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;

    if (!target.name) {
      dispatch(setSearchPage(0));
      dispatch(
        setSelectedFilters(
          "show_Offline",
          target.parentNode.dataset.toggle,
          principal
        )
      );
      return;
    }

    if (target.name === "MythCategory") {
      dispatch(setSearchPage(0));
      dispatch(
        setSelectedFilters("categories", value, searchContext, principal)
      );
      return;
    }

    if (target.type === "radio") {
      if (target.checked) {
        if (name === "rangeType") {
          dispatch(setSearchPage(0));
          dispatch(setScoreType(value, searchContext, principal));
        } else {
          dispatch(setSearchPage(0));
          dispatch(setSelectedFilters(name, value, searchContext, principal));
        }
      }
      return;
    }

    if (target.type === "checkbox") {
      let newArray = [...selectedFilters[name].value];
      if (target.checked) {
        // add checkbox value to state
        newArray.push(value);
      } else {
        // remove checkbox value from state
        newArray = newArray.filter((item) => item !== value);
      }
      dispatch(setSearchPage(0));
      dispatch(setSelectedFilters(name, newArray, searchContext, principal));
    }

    if (target.name.startsWith("score")) {
      const maxScore = searchContext.value === "WEB" ? 100 : 10;
      // get "low" or "high"
      const scoreValueType = target.name.slice(6);
      let scoreValue;
      if (target.value > maxScore) {
        scoreValue = maxScore;
      } else if (target.value < 0) {
        scoreValue = 0;
      } else if (target.value === "") {
        scoreValue = parseInt(target.value, 10);
      }

      dispatch(setSearchPage(0));
      dispatch(
        setScoreFilters(
          scoreValueType,
          searchContext,
          scoreValue || value,
          principal
        )
      );
    }
  };

  const handleCriteriaChange = (value, action) => {
    let changeType = "value";
    const name = action.name;
    const selectedValue = value.value;
    const index = parseInt(name.slice(name.length - 1), 10);

    if (name.startsWith("criteria-toggle")) {
      changeType = "toggleValue";
    }

    dispatch(setSearchPage(0));
    dispatch(
      setCriteriaFilters(
        {
          ...selectedFilters,
          criteria: selectedFilters.criteria.map((el, idx) =>
            idx === index ? { ...el, [changeType]: selectedValue } : el
          ),
        },
        searchContext,
        principal
      )
    );
  };

  const handleRangeChange = (filter, val) => {
    dispatch(setSearchPage(0));
    console.log(filter);
    dispatch(setSelectedFilters(filter?.slug, val, searchContext, principal));
  };

  const handleSubmit = () => {
    dispatch(setSearchPage(0));
    toggleAdvancedFiltersModule();
  };

  function handleOpenSecondModal(e) {
    e.preventDefault();
    toggleAdvancedFiltersModule();
    openSecondModal();
  }

  return (selectedFilters && isOpen) || advanced ? (
    <AdvancedSearchWrapper
      isOpen={isOpen}
      onDismiss={toggleAdvancedFiltersModule}
      aria-label="Advanced Search"
      type="advanced"
    >
      <SiteModule
        name="Advanced Search"
        search={true}
        context="filters"
        isOpen={isOpen || advanced}
        handleClose={toggleAdvancedFiltersModule}
        aria-label="Advanced Search Content"
      >
        <form onSubmit={handleSubmit}>
          {searchContext.value === "MYTH" ? (
            <MythAdvancedSearch
              principal={principal}
              selectedFilters={selectedFilters}
              handleUI={handleUI}
              openFilters={openFilters}
              handleSelectChange={handleSelectChange}
              handleSelectAndRadioChange={handleSelectAndRadioChange}
              handleCriteriaChange={handleCriteriaChange}
              handleTextChange={handleTextChange}
              handleRangeChange={handleRangeChange}
              clear={clear}
            />
          ) : searchContext.value === "REPORT" ? (
            <ReportAdvancedSearch
              principal={principal}
              selectedFilters={selectedFilters}
              handleUI={handleUI}
              openFilters={openFilters}
              handleSelectChange={handleSelectChange}
              handleSelectAndRadioChange={handleSelectAndRadioChange}
              handleCriteriaChange={handleCriteriaChange}
              handleTextChange={handleTextChange}
              clear={clear}
            />
          ) : searchContext.value === "TV" ? (
            <TVAdvancedSearch
              principal={principal}
              selectedFilters={selectedFilters}
              handleUI={handleUI}
              openFilters={openFilters}
              handleSelectChange={handleSelectChange}
              handleSelectAndRadioChange={handleSelectAndRadioChange}
              handleCriteriaChange={handleCriteriaChange}
              handleTextChange={handleTextChange}
              handleToggleChange={handleToggleChange}
              clear={clear}
            />
          ) : searchContext.value === "PODCAST" ? (
            <PodcastAdvancedSearch
              principal={principal}
              selectedFilters={selectedFilters}
              handleUI={handleUI}
              openFilters={openFilters}
              handleSelectChange={handleSelectChange}
              handleSelectAndRadioChange={handleSelectAndRadioChange}
              handleCriteriaChange={handleCriteriaChange}
              handleTextChange={handleTextChange}
              handleToggleChange={handleToggleChange}
              clear={clear}
            />
          ) : (
            <WebAdvancedSearch
              principal={principal}
              selectedFilters={selectedFilters}
              handleUI={handleUI}
              openFilters={openFilters}
              handleSelectChange={handleSelectChange}
              handleSelectAndRadioChange={handleSelectAndRadioChange}
              handleCriteriaChange={handleCriteriaChange}
              handleTextChange={handleTextChange}
              handleToggleChange={handleToggleChange}
              clear={clear}
            />
          )}
          <FilterButtonsContainer
            principal={principal}
            handleClear={clearAll}
            hasFiltersSelected={numberOfFilters > 0}
            results={results}
            isLoading={loading}
            searchContext={searchContext}
            onClick={handleOpenSecondModal}
          />
        </form>
      </SiteModule>
    </AdvancedSearchWrapper>
  ) : null;
}
