import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";
import TagManager from "react-gtm-module";

import Label from "./Label";
import AssociatedDomains from "./AssociatedDomains";
import Metadata from "../Metadata";
import LoadingIcon from "../common/LoadingIcon";
import AuthService from "../../../service/AuthService";
import LabelHistoryModule from "../History/LabelHistoryModule";
import TVIcon from "../common/svg/TVIcon";
import LabelMythExampleLinks from "./LabelMythExampleLinks";
import PodcastIcon from "../common/svg/PodcastIcon";
import Unauthorized from "../Unauthorized/Unauthorized";
import ConnectedRatings from "./ConnectedRatings";
import {
  useLabels,
  useLoadChildLabels,
  useLoadLabelHistory,
  useLoadParentLabels,
  useLoadSecondaryTabData,
} from "./labelQueryHooks";
import TipWithText from "../common/TipWithText";
import LabelPageTooltipContent from "./LabelTabTooltipContext";

const TipWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
`;

const Positioner = styled.div`
  position: relative;
`;

const TabBarWrapper = styled.div`
  overflow-x: auto;
  @media screen and (max-width: 600px) {
    height: fit-content;
    margin-bottom: 30px;
  }
`;

const TabBarStyles = styled.ul`
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0 0 60px 0;
  padding: 0;
  width: fit-content;
  min-width: 100%;
  border-bottom: 6px solid rgba(0, 0, 0, 0.07);
  @media screen and (max-width: 600px) {
    width: fit-content;
    margin-bottom: 0;
    .descriptor {
      display: none;
    }
  }
  @media print {
    display: none;
  }
`;

const TabStyles = styled.li`
  padding: 0 0 10px 0;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 30px;
  cursor: pointer;
  white-space: nowrap;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 6px;
    background-color: #3355dd;
    opacity: ${(props) => (props.isActive ? "1" : "0")};
    transition: opacity 0.3s ease-in-out;
  }
`;

const TitleStyle = styled.h1`
  font-size: 40px;
  line-height: 51px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  word-break: break-all;

  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 25px;
  }

  @media print {
    display: none;
  }
`;

const SubHead = styled.p`
  font-size: 16px;

  span {
    font-size: 14px;
    width: 71ch;
    display: inline-block;
    margin-top: 10px;

    @media screen and (max-width: 600px) {
      width: 34ch;
    }
  }

  a {
    color: var(--primary-blue);
  }

  @media screen and (max-width: 600px) {
    top: -30px;
  }
  @media print {
    font-size: 12px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media screen and (max-width: 600px) {
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

const LabelMain = ({ label, principal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const authService = AuthService.getInstance();

  const [selectedTab, setSelectedTab] = useState("label");
  const { secondaryData, isTabLoading } = useLoadSecondaryTabData(label);
  const [associatedDomains, connectedRatings, labelMythExampleLinks] =
    secondaryData || [];
  const { children, isChildLoading } = useLoadChildLabels(label);
  const { parents } = useLoadParentLabels(label);
  const { labelHistory, isLabelHistoryLoading } = useLoadLabelHistory(label);

  useEffect(() => {
    setSelectedTab("label");
    return () => {
      dispatch({
        type: "SET_LABEL_DOMAIN",
        payload: null,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (!locationState?.selectedTab) {
      setSelectedTab("label");
    } else {
      setSelectedTab(locationState?.selectedTab);
    }
  }, [navigate, locationState]);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        view: "Label",
        tab: selectedTab,
        user: principal?.username,
        partner: principal?.attributes?.partner,
        client_id: principal?.attributes?.client_id,
        content_type: label?.network,
        content_title: label?.identifier,
        content_locale: label?.locale,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [label, selectedTab, principal]);

  const changeTab = (tab) => {
    setSelectedTab(tab);
  };

  const hasMythExampleLinks = labelMythExampleLinks?.length > 0;
  const hasDomains = associatedDomains?.length > 0;
  const hasConnected = connectedRatings?.length > 0;

  const TABS = {
    label: (
      <Label
        label={label}
        children={children}
        childLoading={isChildLoading}
        parents={parents}
      />
    ),
    metadata: <Metadata label={label} />,
    mythExampleLinks: (
      <LabelMythExampleLinks labelMythExampleLinks={labelMythExampleLinks} />
    ),
    associatedDomains: (
      <AssociatedDomains
        maindomain={label?.identifier}
        domains={associatedDomains}
      />
    ),
    connectedRatings: <ConnectedRatings labels={connectedRatings} />,
    history: (
      <LabelHistoryModule
        label={label}
        labelHistory={labelHistory}
        isLabelHistoryLoading={isLabelHistoryLoading}
      />
    ),
  };

  const isTV = label?.network === "TV";
  const isPodcast = label?.network === "PODCAST";

  return label?.id ? (
    <>
      <TitleWrapper isTV={isTV}>
        {isTV && <TVIcon height="42px" width="62px" label />}
        {isPodcast && <PodcastIcon height="42px" width="42px" label />}
        <TitleStyle>
          {label.groupName
            ? `${label.groupName}`
            : label.identifierAlt || label.identifier}
        </TitleStyle>
      </TitleWrapper>
      {!label.active ? (
        <SubHead>
          <span>
            Note: You are viewing an older version of this label.{" "}
            <Link to={`/partner/profile/${label.profileId}`}>
              View the current label
            </Link>
            .
          </span>
        </SubHead>
      ) : (
        ""
      )}
      {!label.siteOnline ? (
        <SubHead>
          {label.network === "WEB" ? (
            <span>
              Note: This website is no longer online. Our Nutrition Label refers
              to the site’s content when it was active.
            </span>
          ) : label.network === "TV" ? (
            <span>
              Note: This program or network is no longer on air. Our Nutrition
              Label refers to the program or network’s content when it was on
              air.
            </span>
          ) : (
            ""
          )}
        </SubHead>
      ) : (
        ""
      )}
      <Positioner>
        <TabBarWrapper>
          <TabBarStyles tabLoading={isTabLoading}>
            <TabStyles
              onClick={() => changeTab("label")}
              isActive={selectedTab === "label"}
            >
              Nutrition Label
            </TabStyles>

            <TabStyles
              onClick={() => changeTab("metadata")}
              isActive={selectedTab === "metadata"}
            >
              Metadata
            </TabStyles>

            {isTabLoading ? (
              <>
                <TabStyles>
                  <LoadingIcon small />
                </TabStyles>
              </>
            ) : (
              <>
                {hasDomains ? (
                  <>
                    <TabStyles
                      onClick={() => changeTab("associatedDomains")}
                      isActive={selectedTab === "associatedDomains"}
                    >
                      Domains
                    </TabStyles>
                  </>
                ) : (
                  ""
                )}

                {hasConnected ? (
                  <>
                    <TabStyles
                      onClick={() => changeTab("connectedRatings")}
                      isActive={selectedTab === "connectedRatings"}
                    >
                      Associated Media
                    </TabStyles>
                  </>
                ) : (
                  ""
                )}

                {hasMythExampleLinks ? (
                  <>
                    <TabStyles
                      onClick={() => changeTab("mythExampleLinks")}
                      isActive={selectedTab === "mythExampleLinks"}
                    >
                      False Narratives
                    </TabStyles>
                  </>
                ) : (
                  ""
                )}
              </>
            )}

            {authService.hasLabelHistoryAccess(principal) && ( //label.country !== "ALL" &&
              <>
                <TabStyles
                  onClick={() => changeTab("history")}
                  isActive={selectedTab === "history"}
                >
                  <span className="descriptor">Rating</span> History
                </TabStyles>
              </>
            )}
          </TabBarStyles>
          {selectedTab !== "label" && (
            <TipWrapper>
              <TipWithText align="right">
                <LabelPageTooltipContent tab={selectedTab} />
              </TipWithText>
            </TipWrapper>
          )}
        </TabBarWrapper>
      </Positioner>

      {TABS[selectedTab]}
    </>
  ) : (
    ""
  );
};
const LabelHome = (props) => {
  const principal = useSelector((state) => state.authentication?.principal);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [network, setNetwork] = useState("DEFAULT");
  const [authorized, setAuthorized] = useState(true);
  const { labelId } = useParams() || props.labelId;
  const { profileId } = useParams() || props.profileId;
  const authService = AuthService.getInstance();

  const { label, isLabelLoading, error } = useLabels(labelId, profileId);

  const processAuthorized = useCallback(() => {
    if (
      authService.hasTVAccess(principal) ||
      authService.hasPodcastAccess(principal) ||
      authService.hasLabelAccess(principal)
    ) {
      if (label?.network === "TV") {
        return authService.hasTVAccess(principal);
      } else if (label?.network === "PODCAST") {
        return authService.hasPodcastAccess(principal);
      }
      return authService.hasLabelAccess(principal);
    } else {
      return false;
    }
  }, [principal, label, authService]);

  useEffect(() => {
    if (!isLabelLoading) {
      if (label?.id) {
        setAuthorized(processAuthorized());
      } else {
        setAuthorized(false);
      }
    }
  }, [principal, label, processAuthorized]);

  useEffect(() => {
    if (error?.response?.status === 403) {
      setAuthorized(false);
      setNetwork(error?.response?.response?.value);
    }
  }, [error]);

  useEffect(() => {
    if (label) {
      dispatch({
        type: "SET_LABEL_DOMAIN",
        payload: label.identifier,
      });
      dispatch({
        type: "SET_SEARCH_CONTEXT",
        payload: {
          value: label.network,
          label: label.network,
        },
      });
    }
  }, [dispatch, label]);

  return isLabelLoading ? (
    <LoadingIcon />
  ) : authorized ? (
    <LabelMain label={label} principal={principal} />
  ) : (
    <Unauthorized type={network} />
  );
};

export default LabelHome;
