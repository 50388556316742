import styled from "@emotion/styled/macro";
import Tip from "./Tooltip";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: fit-content;

  // if align prop equals right, set margin-left to auto
  margin-left: ${(props) => (props.align === "right" ? "auto" : "0")};
`;
function TipWithText({ text = "About This Data", children, align }) {
  return (
    <Wrapper align={align}>
      <div>About This Data</div>
      <Tip className="Tooltip-wide">{children}</Tip>
    </Wrapper>
  );
}

export default TipWithText;
