import languages from "./locales/language.json";
import AuthService from "../service/AuthService";
import TagManager from "react-gtm-module";

export const decodeHtml = (html) => {
  if (html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }
  return "";
};

export const LocaleValue = (country, language) => {
  return `${languages[language] ? languages[language] : language} ${
    country && country !== "ALL" ? `(${country})` : ""
  }`;
};

export const redirectToRoute = (navigate) => {
  const returnLocation = AuthService.getInstance().getSavedRoute();
  if (returnLocation) {
    sessionStorage.removeItem("_auth_return_location");
    navigate(returnLocation);
  } else {
    navigate("/subscription/offer");
  }
};

export const gtmDataLayer = (
  event,
  principal,
  view,
  tab,
  content_type,
  content_title
) => {
  const tagManagerArgs = {
    dataLayer: {
      event: event,
      user: principal?.username,
      partner: principal?.attributes?.partner,
      client_id: principal?.attributes?.client_id,
      view: view,
      tab: tab,
      content_type: content_type,
      content_title: JSON.stringify(content_title),
    },
  };

  TagManager.dataLayer(tagManagerArgs);
};
