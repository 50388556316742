const LabelPageTooltipContent = ({ tab }) => {
  switch (tab) {
    case "label":
      return null;
    case "metadata":
      return (
        <p>
          This page contains descriptive data points captured by NewsGuard’s
          analysts in the course of rating the site. Hover over the “i” icon for
          a definition of each data field, or contact{" "}
          <a href="mailto:partnerships@newsguardtech.com">
            partnerships@newsguardtech.com
          </a>{" "}
          for an up-to-date data definitions document.
        </p>
      );
    case "associatedDomains":
      return (
        <p>
          This page contains a list of all domains covered by this rating and
          Nutrition Label. Domains listed may be aliases for the main website
          listed, or may be part of a network with the same ownership and
          similar practices.
        </p>
      );
    case "connectedRatings":
      return (
        <div>
          If NewsGuard has rated other media related to this source, links to
          those ratings are provided below.
        </div>
      );
    case "mythExampleLinks":
      return (
        <>
          <p>
            Below is a list of instances in which this publisher appears to have
            published false claims from NewsGuard’s Misinformation Fingerprints
            database. For access to the Misinformation Fingerprints, contact{" "}
            <a href="mailto:partnerships@newsguardtech.com">
              partnerships@newsguardtech.com
            </a>
            .
          </p>
          <br />
          <p>
            Note: This data is collected separately from NewsGuard’s rating
            process. For detailed evidence supporting this site’s rating,
            instead view the Nutrition Label tab.
          </p>
        </>
      );
    case "history":
      return (
        <p>
          NewsGuard periodically updates its rating of each publication. Below
          is a list of past versions of this publication’s rating and Nutrition
          Label. Any changes in criteria results or score are noted.
        </p>
      );
    default:
      return null;
  }
};

export default LabelPageTooltipContent;
