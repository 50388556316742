import React, { useEffect, useReducer, useState } from "react";
import AuthService from "../../service/AuthService";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "@emotion/styled/macro";

import LoadingIndicator from "../../common/LoadingIndicator";
import {
  Fieldset,
  Form,
  LinkButton,
  Title,
} from "../Subscription/SignupStyles";
import InputModule from "../FormElements/InputModule";
import ErrorMessage from "../FormElements/ErrorMessage";

import { Button } from "../FormElements/Button";
import AppReduxStore from "../../AppReduxStore";
import { LOGOUT } from "../../_actions";

const Container = styled.div`
  width: 360px;
  margin: 50px auto 0;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;

  @media screen and (max-width: 600px) {
    width: auto;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 20px;
`;

const SuccessWrapper = styled.div`
  margin-bottom: 40px;
`;

const ResetPassword = (props) => {
  const authService = AuthService.getInstance();

  const [query] = useSearchParams();
  const params = useParams();

  const [formFields, setFormFields] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      email: authService.getEmail(),
      password: "",
      confirmPassword: "",
    }
  );

  const [newToken, setNewToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [validated, setValidated] = useState(false);
  const [html5error, setHtml5error] = useState({});

  useEffect(() => {
    setLoading(true);
    authService.logOut(false);

    const email =
      query.get("email") || params.email || authService.getStoredValue("email");
    const ptoken =
      query.get("token") ||
      params.token ||
      authService.getStoredValue("ptoken");

    authService
      .validateResetPasswordToken(email, ptoken)
      .then((response) => {
        const accessToken = response["access_token"];
        setFormFields({ ["email"]: email });
        setNewToken(accessToken);
      })
      .catch((error) => {
        setError({
          response: {
            code: "password.reset.token.invalid",
            message:
              "The password reset token is invalid, expired, or already used.",
          },
        });
      })
      .finally((res) => setLoading(false));
  }, []);

  const handleInputChange = (event) => {
    event.target.setCustomValidity(""); // have to reset custom validity else field never returns to valid
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    setHtml5error((prevState) => ({
      ...prevState,
      [inputName]: undefined,
    }));
    setFormFields({ [inputName]: inputValue });
  };

  const validate = (event) => {
    setValidated(true);
    return event.target.checkValidity();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    setHtml5error({});

    if (validate(event)) {
      authService
        .updatePassword({
          password: formFields.password,
        })
        .then((response) => {
          setLoading(false);
          setSuccess(true);
          setSuccessMsg(response.message);
        })
        .catch((error) => {
          setError(error);
        })
        .finally((res) => {
          setLoading(false);
          authService.logOut(false);
        });
    } else {
      setLoading(false);
    }
  };

  const validateConfirmPassword = (e) => {
    const passwordField = document.querySelector("#password");
    const confirmPasswordField = document.querySelector("#confirmPassword");
    if (confirmPasswordField.value !== passwordField.value) {
      confirmPasswordField.setCustomValidity(() => {
        let confirmPassword = {
          id: "message.password_confirm_instruction",
          defaultMessage:
            "Please enter matching passwords for Password and Confirm Password",
          name: "confirmPassword",
          html: true,
        };

        setHtml5error((prevState) => ({
          ...prevState,
          confirmPassword,
        }));
      });
    }
  };

  const checkIsValid = (e) => {
    if (e.target.checkValidity() === false) {
      e.target.parentNode.classList.add("was-validated");
    }
    if (
      e.target.id === "confirmPassword" ||
      e.target.id === "resetPasswordForm"
    ) {
      validateConfirmPassword();
    }
  };

  const intl = useIntl();
  return (
    <Container>
      <Form
        className={`  ${validated ? "was-validated" : ""}`}
        onSubmit={handleSubmit}
        disabled={loading}
        aria-busy={loading}
        noValidate
        id="resetPasswordForm"
      >
        <Fieldset>
          <Title>
            <FormattedMessage
              id="template.change_password"
              defaultMessage="Change Password"
            />
          </Title>
          {error ? <ErrorMessage error={error} /> : ""}
          {success && successMsg ? (
            <SuccessWrapper>
              <TextWrapper>
                <FormattedMessage
                  id={successMsg}
                  defaultMessage="Password is Updated!"
                />
              </TextWrapper>
              <LinkButton
                to="/signin"
                className="login"
                onClick={() => {
                  AppReduxStore.dispatch({ type: LOGOUT });
                }}
              >
                {" "}
                <FormattedMessage
                  id="template.signin"
                  defaultMessage="Sign In"
                />
              </LinkButton>
            </SuccessWrapper>
          ) : (
            <>
              {" "}
              <InputModule
                type="password"
                identifier="password"
                placeholder={intl.formatMessage({
                  id: "template.password",
                  defaultMessage: "Password",
                })}
                value={formFields.password}
                onChange={handleInputChange}
                onBlur={checkIsValid}
                autoComplete="new-password"
                minLength={8}
                maxLength={50}
                required={true}
                onInvalid={() => {
                  let password = {
                    id: "message.password_length_instruction",
                    defaultMessage:
                      "Please enter a password that is 8 characters or longer",
                    name: "password",
                    html: true,
                  };
                  setHtml5error((prevState) => ({
                    ...prevState,
                    password,
                  }));
                }}
                error={html5error.password}
              />
              <InputModule
                type="password"
                identifier="confirmPassword"
                placeholder={intl.formatMessage({
                  id: "template.confirm_password",
                  defaultMessage: "Confirm Password",
                })}
                value={formFields.confirmPassword}
                onChange={handleInputChange}
                onBlur={checkIsValid}
                required={true}
                auto-complete="new-password"
                onInvalid={() => {
                  let confirmPassword = {
                    id: "message.password_confirm_instruction",
                    defaultMessage: "Please enter matching passwords",
                    name: "confirmPassword",
                    html: true,
                  };
                  setHtml5error((prevState) => ({
                    ...prevState,
                    confirmPassword,
                  }));
                }}
                error={html5error.confirmPassword}
              />
              <Button type="submit">
                {loading ? (
                  <LoadingIndicator button />
                ) : (
                  <FormattedMessage
                    id="template.submit"
                    defaultMessage="Submit"
                  />
                )}
              </Button>
              <Link
                to="#"
                className="login"
                onClick={() => {
                  AppReduxStore.dispatch({ type: LOGOUT });
                }}
                style={{ textAlign: "center", display: "block" }}
              >
                {" "}
                <FormattedMessage id="template.login" defaultMessage="Log In" />
              </Link>
            </>
          )}
        </Fieldset>
      </Form>
    </Container>
  );
};

export default ResetPassword;
