import { Link } from "react-router-dom";
import { useCardLink } from "./useCardLink";
import {
  LabelDescriptionStyling,
  LabelMetaAndScoreContainer,
  LabelNameStyling,
  MetaDataContent,
  MetadataWrapper,
  SearchResultLink,
  SearchResultStyles,
  SearchResultWrapper,
} from "./SearchResultsStyles";
import MythMarker from "../common/MythMarker";
import React from "react";
import { LocaleValue } from "../../../common/Helper";
import { ExampleMetadata } from "../Myths/MythStyles";
import { getLocale } from "../../../common/_localeMessages";
import formattedDate from "../Helpers/formattedDate";

export default function MythPublishedSearchResult({
  mythPublished,
  index,
  localeLabels,
}) {
  const [cardRef, linkRef] = useCardLink();
  const myth = mythPublished?.labels[0];
  const metadata = mythPublished?.metadata;
  const variationAvailableLocales = mythPublished?.availableLocales || [];

  if (myth) {
    return (
      <SearchResultWrapper key={`result-${index}`} ref={cardRef}>
        <SearchResultStyles>
          <div className="labelInfo">
            <LabelNameStyling>
              <MythMarker SearchResult developing={myth.developing} />
              <SearchResultLink
                to={`/partner/myth/profile/${myth.mythWorkId}`}
                ref={linkRef}
              >
                {mythPublished.variation
                  ? myth.identifier
                  : myth.title || myth.identifier}
              </SearchResultLink>
            </LabelNameStyling>
            <LabelDescriptionStyling
              dangerouslySetInnerHTML={{
                __html: mythPublished.variation
                  ? `A variation of the False Narrative: ${myth.title}`
                  : myth.writeup?.description?.body,
              }}
            />
            {
              //If the risk of harm is Medium or Low, or if no risk of harm is designated, do not display this.
            }
            {mythPublished.metadata?.harm_risk?.body &&
            (mythPublished.metadata?.harm_risk?.body === "Highest" ||
              mythPublished.metadata?.harm_risk?.body === "High") ? (
              <ExampleMetadata>
                <svg
                  width="30px"
                  height="30px"
                  viewBox="0 0 60 60"
                  version="1.1"
                >
                  <title>Flag icon</title>
                  <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="#000"
                    fillRule="evenodd"
                  >
                    <g id="ii/system/flag" fill="%23000000">
                      <path
                        d="M20,15 L44,27 L23,37.5 L23,47 L20,47 L20,15 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </svg>
                <div>
                  Risk of Harm:{" "}
                  {mythPublished?.metadata?.harm_risk?.body?.toUpperCase()}
                </div>
              </ExampleMetadata>
            ) : (
              ""
            )}
          </div>
          <LabelMetaAndScoreContainer myth>
            <MetadataWrapper myth>
              {!mythPublished.variation &&
              metadata?.date_first_appearance?.body ? (
                <>
                  <h4>Appeared</h4>
                  <span className="appeared_date">
                    {formattedDate(
                      metadata.date_first_appearance.body,
                      getLocale(),
                      {
                        year: "numeric",
                        month:
                          metadata.date_first_appearance.body?.split("-")
                            .length > 1
                            ? "short"
                            : undefined,
                        day:
                          metadata.date_first_appearance.body?.split("-")
                            .length > 2
                            ? "2-digit"
                            : undefined,
                        timeZone: "UTC",
                      }
                    )}
                  </span>
                </>
              ) : (
                ""
              )}
              {mythPublished.mythExampleLinks ? (
                <>
                  <h4>Examples</h4>
                  <span className="appeared_date">
                    {mythPublished.mythExampleLinks.length}
                  </span>
                </>
              ) : (
                ""
              )}
              <MetaDataContent>
                <h4>Locale</h4>
                <span className="locale">
                  {mythPublished.variation &&
                  variationAvailableLocales.length > 0
                    ? variationAvailableLocales.map((availableLocale) => (
                        <div
                          key={`"var_available_locale_${availableLocale.documentId}`}
                        >
                          <Link
                            to={`/partner/myth/${availableLocale.documentId}`}
                          >
                            {LocaleValue(
                              availableLocale.country,
                              availableLocale.language
                            )}
                          </Link>
                        </div>
                      ))
                    : localeLabels.map((localeLabel) => (
                        <div key={localeLabel.id}>
                          <Link to={`/partner/myth/${localeLabel.id}`}>
                            {LocaleValue(
                              localeLabel.country,
                              localeLabel.language
                            )}
                          </Link>
                        </div>
                      ))}
                </span>
              </MetaDataContent>
            </MetadataWrapper>
          </LabelMetaAndScoreContainer>
        </SearchResultStyles>
      </SearchResultWrapper>
    );
  }
  return "";
}
