import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMythExampleLinks, useMythMedia, useMyths } from "./useMyths";
import LoadingIcon from "../common/LoadingIcon";
import MythView from "./MythView";
import Unauthorized from "../Unauthorized/Unauthorized";

const Myth = (props) => {
  const mythId = useParams().mythId || props.mythId;
  const profileId = useParams().profileId || props.profileId;
  const [authorized, setAuthorized] = useState(true);

  const { myth, isLoading, firstAppeared, error } = useMyths(profileId, mythId);
  const { mythExampleLinks, profiles, isMythExampleLinksLoading } =
    useMythExampleLinks(myth);
  const { mythMedia, isMythMediaLoading } = useMythMedia(myth);

  useEffect(() => {
    if (error?.response?.status === 403) {
      setAuthorized(false);
    }
  }, [error]);

  return isLoading ? (
    <LoadingIcon />
  ) : authorized ? (
    <MythView
      myth={myth}
      firstAppeared={firstAppeared}
      mythExampleLinks={mythExampleLinks}
      profiles={profiles}
      isMythExampleLinksLoading={isMythExampleLinksLoading}
      mythMedia={mythMedia}
      isMythMediaLoading={isMythMediaLoading}
    />
  ) : !authorized ? (
    <Unauthorized myth={true} />
  ) : (
    ""
  );
};

export default Myth;
