import styled from "@emotion/styled/macro";

export default function DateRangeFilter({
  daveValue = { start: "", end: "" },
  minDate = "",
  defaultStartDate = "",
  defaultEndDate = "",
  onChange,
}) {
  const maxDate = new Date().toISOString().split("T")[0];

  const DateInput = styled.input`
    border: 1px solid var(--primary-black);
    border-radius: 2px;

    &:focus-visible {
      outline: none;
      border: 1px solid var(--primary-blue);
    }
  `;

  const RangeDividerStyle = styled.span`
    display: inline-block;
    margin: 0 16px;
    color: ${(props) => (props.disabled ? "rgba(0,0,0,0.08)" : "#000")};

    @media screen and (max-width: 600px) {
      margin: 0 10px;
    }
  `;

  const handleDateChange = (e) => {
    let newDateRange = Object.assign({}, daveValue);
    newDateRange[e.target.name] = e.target.value;
    onChange(newDateRange);
  };

  return (
    <>
      <DateInput
        type="date"
        value={daveValue.start || defaultStartDate}
        id="start"
        name="start"
        min={minDate}
        max={daveValue.end || maxDate}
        onChange={handleDateChange}
      />
      <RangeDividerStyle>-</RangeDividerStyle>
      <DateInput
        type="date"
        value={daveValue.end || defaultEndDate || maxDate}
        id="end"
        name="end"
        min={daveValue.start}
        max={maxDate}
        onChange={handleDateChange}
      />
    </>
  );
}
