import AuthService from "../../../../service/AuthService";
import ContentService from "../../../../service/ContentService";

const getData = async (
  searchTerm,
  advancedFilters = [],
  page,
  sizePerPage,
  searchContext,
  principal
) => {
  window.scrollTo(0, 0);
  const filterInfo = formatFilters(
    searchTerm,
    advancedFilters,
    searchContext,
    principal
  );

  let sort = {};

  if (advancedFilters.sortBy?.value) {
    sort[advancedFilters.sortBy?.value] =
      advancedFilters.sortOrder?.value || "desc";
  }

  return await ContentService.getInstance().search(
    searchTerm,
    filterInfo,
    sort,
    page,
    sizePerPage
  );
};

export const downloadData = async (
  searchTerm,
  advancedFilters = [],
  searchContext,
  principal
) => {
  const filterInfo = formatFilters(
    searchTerm,
    advancedFilters,
    searchContext,
    principal
  );

  let sort = {};

  if (advancedFilters.sortBy?.value) {
    sort[advancedFilters.sortBy?.value] =
      advancedFilters.sortOrder?.value || "desc";
  }

  return await ContentService.getInstance().searchDownload(
    searchTerm,
    filterInfo,
    sort,
    searchContext
  );
};
const formatFilters = (
  searchTerm,
  advancedFilters = [],
  searchContext,
  principal
) => {
  const filters = {};

  filters["type"] = {
    key: "type",
    filterVal:
      searchContext ||
      (AuthService.getInstance().hasLabelAccess(principal)
        ? "WEB"
        : AuthService.getInstance().hasTVAccess(principal)
        ? "TV"
        : AuthService.getInstance().hasPodcastAccess(principal)
        ? "PODCAST"
        : AuthService.getInstance().hasMythAccess(principal) ||
          AuthService.getInstance().hasRecentMythOnlyAccess(principal)
        ? "MYTH"
        : AuthService.getInstance().hasOnlineReportAccess(principal)
        ? "REPORT"
        : "NONE"),
  };

  const criteriaFilterInfo = [];
  if (advancedFilters?.criteria) {
    advancedFilters.criteria.forEach((criteria) => {
      if (criteria && criteria.value && criteria.toggleValue) {
        criteriaFilterInfo.push({
          key: criteria.value,
          filterVal: criteria.toggleValue,
        });
      }
    });
  }

  const setFilterValues = (advancedFilters, filterName) => {
    if (
      advancedFilters &&
      advancedFilters[filterName] &&
      advancedFilters[filterName].value &&
      Array.isArray(advancedFilters[filterName].value)
    ) {
      return advancedFilters[filterName].value
        .filter((tag) => tag)
        .map((tag) => (tag.value ? tag.value : tag));
    }
    return [];
  };

  const topicFilterInfo = setFilterValues(advancedFilters, "topics");
  const flagsFilterInfo = setFilterValues(advancedFilters, "flags");
  const mediumFilterInfo = setFilterValues(advancedFilters, "medium");
  const coverageFilterInfo = setFilterValues(advancedFilters, "coverage");

  const orientationFilterInfo = setFilterValues(advancedFilters, "orientation");

  const dmaFilterInfo = setFilterValues(advancedFilters, "location");

  const localeFilterInfo = setFilterValues(advancedFilters, "locale");

  const ratingsFilterInfo = setFilterValues(advancedFilters, "ratings");

  let subjectTagsFilterInfo = setFilterValues(advancedFilters, "subject_tags");
  let influenceOpsTagsFilterInfo = setFilterValues(
    advancedFilters,
    "influence_ops"
  );

  let publisherTypeFilterInfo = setFilterValues(
    advancedFilters,
    "publisher_type"
  );

  let harmRiskFilterInfo = setFilterValues(advancedFilters, "harm_risk");
  let mythLocationFilterInfo = setFilterValues(
    advancedFilters,
    "myth_location"
  );

  let mythSpreadLocationFilterInfo = setFilterValues(
    advancedFilters,
    "spread_location"
  );

  let mythManipulatedMediaFilterInfo = setFilterValues(
    advancedFilters,
    "manipulated_media"
  );

  const communitiesServedFilterInfo = setFilterValues(
    advancedFilters,
    "communities_served"
  );

  const bgSegmentsFilterInfo = setFilterValues(advancedFilters, "bg_segments");

  const advancedFilterInfo = {
    topic: { filterType: "LIST", filterValList: [...topicFilterInfo] },
    flags: { filterType: "LIST", filterValList: [...flagsFilterInfo] },
    medium: { filterType: "LIST", filterValList: [...mediumFilterInfo] },
    coverage: { filterType: "LIST", filterValList: [...coverageFilterInfo] },
    orientation: {
      filterType: "LIST",
      filterValList: [...orientationFilterInfo],
    },
    locale: { filterType: "LIST", filterValList: [...localeFilterInfo] },
    dma: { filterType: "LIST", filterValList: [...dmaFilterInfo] },
    ratings: { filterType: "LIST", filterValList: [...ratingsFilterInfo] },
    score: {
      filterVal: advancedFilters?.score?.value?.low || 0,
      filterVal2:
        (advancedFilters?.score?.scoreType || "") === "range"
          ? advancedFilters?.score?.value?.high || 100
          : advancedFilters?.score?.value?.low || 100,
    },
    criteria: {
      filterType: "MULTISELECT",
      subFilters: criteriaFilterInfo,
    },
    owner: {
      filterType: "text",
      filterVal: advancedFilters?.owner?.value || "",
    },
    categories: {
      filterValList: [...advancedFilters?.categories?.value],
      filterType: "LIST",
      key: "category",
    },
    subject_tags: {
      filterValList: [...subjectTagsFilterInfo],
      filterType: "LIST",
      key: "subject_tags",
    },
    influence_ops: {
      filterValList: [...influenceOpsTagsFilterInfo],
      filterType: "LIST",
      key: "influence_ops",
    },
    publisher_type: {
      filterValList: [...publisherTypeFilterInfo],
      filterType: "LIST",
    },
    show_offline: {
      filterVal: advancedFilters?.show_offline?.value || false,
      key: "show_offline",
    },
    communities_served: {
      filterValList: [...communitiesServedFilterInfo],
      filterType: "LIST",
    },
    bg_segments: {
      filterValList: [...bgSegmentsFilterInfo],
      filterType: "LIST",
    },
    tv_network: {
      filterType: "text",
      filterVal: advancedFilters?.tv_network?.value || "",
    },
    opinion: {
      filterType: "text",
      filterVal: advancedFilters?.opinion?.value || "",
    },
    harm_risk: {
      filterValList: [...harmRiskFilterInfo],
      filterType: "LIST",
    },
    myth_location: {
      filterValList: [...mythLocationFilterInfo],
      filterType: "LIST",
    },
    myth_spread_location: {
      filterValList: [...mythSpreadLocationFilterInfo],
      filterType: "LIST",
    },
    first_appearance_date: {
      filterVal: advancedFilters?.first_appearance_date?.value?.start,
      filterVal2: advancedFilters?.first_appearance_date?.value?.end,
    },
    manipulated_media: {
      filterValList: [...mythManipulatedMediaFilterInfo],
      filterType: "LIST",
    },
  };

  return Object.assign(filters, advancedFilterInfo);
};

export default getData;
