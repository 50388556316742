import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AppReduxStore from "./AppReduxStore";

import SignIn from "./components/SignIn";
import SignOut from "./components/SignOut";
import ForgotPassword from "./components/ForgotPassword";
import Offer from "./components/Subscription/Offer";

import OAuth2RedirectHandler from "./oauth2/OAuth2RedirectHandler";
import AppleSignRedirectHandler from "./oauth2/AppleSignRedirectHandler";

import AuthService from "./service/AuthService";
import AccountService from "./service/AccountService";

import ResetPassword from "./components/Account/ResetPassword";
import VoucherActivation from "./components/Voucher/Activation";
import VoucherRedeemed from "./components/Voucher/Redeemed";

import Error from "./components/Error";

import ConfigService from "./service/ConfigService";
import TagManager from "react-gtm-module";

import AppleAppSigninRedirectHandler from "./oauth2/AppleAppSigninRedirectHandler";
import PartnerHome from "./components/Partners/PartnerHome";

import MainLayout from "./common/MainLayout";
import LabelHome from "./components/Partners/Labels/LabelHome";
import Myth from "./components/Partners/Myths/Myth";
import Report from "./components/Partners/Reports/Report";
import ContentService from "./service/ContentService";
import ScrollToTop from "./components/Partners/Helpers/ScrollToTop";
import Search from "./components/Partners/Search/Search";

import EmailPreferences from "./components/Partners/Settings/EmailPreferences";
import AccountLayout from "./components/Account/AccountLayout";
import PitchPage from "./pages/pitch";
import SignUpLayout from "./common/SignUpLayout";
import SignUp from "./components/SignUp";
import PurchasePreview from "./components/Subscription/PurchasePreview";
import PurchaseComplete from "./components/Subscription/PurchaseComplete";
import PaymentHistory from "./components/Account/PaymentHistory";
import RequireAuth from "./Auth/AuthAndRolesCheck";
import extensionCheck from "./config/extensionCheck";
import LoadingIndicator from "./common/LoadingIndicator";
import MythMedia from "./components/Partners/Myths/MythMedia";
import DevelopingMyth from "./components/Partners/Myths/DevelopingMyth";

// const queryClient = new QueryClient();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 1000 * 60 * 60,
    },
  },
});
const App = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const configService = ConfigService.getInstance();

  useEffect(() => {
    const loadConfig = async () => {
      setIsLoading(true);
      await configService
        .load()
        .then(async () => {
          await initApp();
        })
        .catch(async (error) => {
          await initApp();
        });
      setIsLoading(false);
    };

    loadConfig();
  }, [configService]);

  const initApp = async () => {
    //console.log("initApp");
    extensionCheck();

    AuthService.getInstance().updateConfig(configService._data);
    AccountService.getInstance().updateConfig(configService._data);
    ContentService.getInstance().updateConfig(configService._data);

    await AccountService.getInstance()
      .retrieveUserInfoFromServer()
      .catch((error) => {
        if (error.code === 401 || error.status === 401) {
          console.log("just not logged in", error);
        }
      })
      .finally((res) => {
        console.log("done retrieveUser");
      });

    const gtmId = configService.get().gaId;
    const tagManagerArgs = {
      gtmId: gtmId,
      events: {
        PartnerView: "Partner View",
      },
    };

    await TagManager.initialize(tagManagerArgs);
  };

  return !isLoading ? (
    <Provider store={AppReduxStore}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route element={<RequireAuth allowedRoles={["PARTNER"]} />}>
                  <Route index element={<PartnerHome />} />
                  <Route path="partner">
                    <Route index element={<PartnerHome />} />
                    <Route path="home" element={<PartnerHome />} />
                    <Route path="search" element={<Search />} />
                    <Route path="label/:labelId" element={<LabelHome />} />
                    <Route path="profile/:profileId" element={<LabelHome />} />
                    <Route path="myth/profile/:profileId" element={<Myth />} />
                    <Route
                      path="myth/profile/:profileId/media/:mediaId"
                      element={<MythMedia />}
                    />
                    <Route
                      path="myth/developing/:mythMainId"
                      element={<DevelopingMyth />}
                    />
                    <Route path="myth/:mythId" element={<Myth />} />
                    <Route
                      path="report/profile/:profileId"
                      element={<Report />}
                    />
                    <Route path="report/:reportId" element={<Report />} />
                    <Route
                      path="settings/preferences/"
                      element={<AccountLayout />}
                    >
                      <Route path=":type" element={<EmailPreferences />} />
                    </Route>
                  </Route>
                </Route>
                <Route
                  element={<RequireAuth allowedRoles={["NON_CUSTOMER"]} />}
                >
                  <Route path="signin" element={<SignIn />} />
                </Route>

                <Route path="signout" element={<SignOut />} />
                <Route path="forgotPassword" element={<ForgotPassword />} />

                <Route path="resetPassword" element={<ResetPassword />}>
                  <Route path=":email/:token" element={<ResetPassword />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={["CUSTOMER"]} />}>
                  <Route path="account" element={<AccountLayout />}>
                    <Route path="accountsettings" element={<AccountLayout />} />
                    <Route path="paymentmethod" element={<AccountLayout />} />
                    <Route path="redeem" element={<AccountLayout />} />
                    {/* <Route path="redeemed" element={<VoucherRedeemed />} /> */}
                    <Route path="membership" element={<AccountLayout />} />
                    <Route path="billing" element={<AccountLayout />} />
                  </Route>
                  <Route
                    path="account/paymenthistory"
                    element={<PaymentHistory />}
                  />
                </Route>
                <Route
                  element={<RequireAuth allowedRoles={["NON_CUSTOMER"]} />}
                >
                  {/* /get-newsguard is the pitch page */}
                  <Route path="get-newsguard" element={<PitchPage />}>
                    <Route path=":campaign" element={<PitchPage />} />
                  </Route>
                </Route>

                {/* account/redeemed isn't used */}
                <Route path="account/redeemed" element={<VoucherRedeemed />} />
                <Route
                  element={<RequireAuth allowedRoles={["NON_CUSTOMER"]} />}
                >
                  <Route path="redeem" element={<VoucherActivation />} />
                  <Route path="redeemed" element={<VoucherRedeemed />} />
                </Route>
                <Route path="errorpage" element={<Error />} />
              </Route>

              <Route element={<SignUpLayout />}>
                <Route element={<RequireAuth allowedRoles={["CUSTOMER"]} />}>
                  <Route path="subscription">
                    <Route path="offer" element={<Offer />} />
                    <Route path="preview" element={<PurchasePreview />} />
                    <Route path="complete" element={<PurchaseComplete />} />
                    <Route index element={<Offer />} />
                    {
                      // <Route path=":campaign" element={<Offer />} />
                    }
                  </Route>
                </Route>
                <Route
                  element={<RequireAuth allowedRoles={["NON_CUSTOMER"]} />}
                >
                  <Route path="offer">
                    <Route index element={<Offer />} />
                    {
                      // <Route path=":campaign" element={<Offer />} />
                    }
                  </Route>

                  <Route path="signup">
                    <Route index element={<SignUp />} />
                  </Route>
                </Route>
              </Route>

              <Route
                path="/oauth2/redirect"
                element={<OAuth2RedirectHandler />}
              />
              <Route
                path="/apple/redirect"
                element={<AppleSignRedirectHandler />}
              />
              <Route
                path="/account/app"
                element={<AppleAppSigninRedirectHandler />}
              />
              <Route
                path="/account/app/voucher"
                element={<AppleAppSigninRedirectHandler />}
              />
            </Routes>
          </ScrollToTop>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  ) : (
    <LoadingIndicator />
  );
};

export default App;
