import React, { useRef } from "react";
import styled from "@emotion/styled/macro";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import RatingView from "../RatingView";
import Byline from "../Byline";
import PrintLogo from "../common/PrintLogo";
import TimeslotIcon from "../common/svg/TimeslotIcon";
import Actions from "../common/Actions";
import ChildLabels from "./ChildLabels";

const PageWrapper = styled.div`
  @page {
    margin: 60px;
  }
`;

const TitleStyle = styled.h1`
  display: none;
  word-break: break-all;

  @media print {
    display: block;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 6px solid rgba(0, 0, 0, 0.07);
  }
`;

const LabelContainerStyles = styled.div`
  display: grid;
  grid-template-columns: minmax(450px, 760px) minmax(285px, 1fr);
  grid-template-rows: min-content 3fr;
  grid-column-gap: 46px;

  .scoring {
    grid-column: 2;
    grid-row: 1 / -1;
  }

  .label {
    grid-column: 1;
  }

  @media (max-width: 924px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    .scoring {
      grid-column: 1;
      grid-row: 2;
    }
  }

  h2 {
    margin: 40px 0 20px 0;
  }

  @media print {
    display: block;
  }
`;

const MetadataWrapper = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 20px;
  }

  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

const MetadataText = styled.p`
  display: flex;
  align-items: center;

  & a {
    text-decoration: none;
    color: var(--primary-blue);

    &:hover {
      text-decoration: underline;
    }
  }
`;

const LabelBlurbStyles = styled.div`
  grid-column: 1;
  padding-bottom: 20px;
`;

const LabelTopLineDesecriptionStyles = styled.p`
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 30px;
    font-variation-settings: "wght" 100;
    margin-bottom: 10px;
  }

  @media print {
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
`;

const LabelSectionHeaderStyles = styled.h3`
  font-size: 20px;

  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const LabelSectionTextStyles = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  font-weight: 400;

  & a {
    color: var(--primary-blue) !important; // overriding embedded html styles
    text-decoration: underline !important;
  }

  &:last-of-type {
    margin-bottom: 45px;
  }
`;

const LabelSectionSourceWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

const LabelSectionSourceTextStyles = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  overflow-wrap: anywhere;

  :last-of-type:not(:only-of-type) {
    margin-bottom: 35px;
  }

  a {
    color: var(--primary-blue);
    text-decoration: none;
    display: inline-block;

    :hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    word-break: break-all;
    margin-bottom: 15px;

    :last-of-type:not(:only-of-type) {
      margin-bottom: 35px;
    }
  }
`;

const SourceHead = styled.h2`
  page-break-inside: avoid;
`;

const MetadataTime = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const OrderedList = styled.ol`
  padding: 0 0 0 15px;
  margin: 0;

  span a {
    color: #3355dd;
    text-decoration: underline !important;
  }

  li a {
    display: inline-block;
    margin-left: 3px;
  }
`;

const SourceHeader = () => {
  return (
    <SourceHead>
      <FormattedMessage id={"msg.sources"} defaultMessage={"Sources"} />
    </SourceHead>
  );
};

const Source = ({ sources }) => {
  if (sources && sources.length > 0) {
    if (sources.length === 1) {
      if (sources[0].sources && sources[0].sources.length > 0) {
        return (
          <div>
            <SourceHeader />
            {sources[0].sources.map((bullet, index) => (
              <LabelSectionSourceWrapper key={`source_${index}`}>
                <LabelSectionSourceTextStyles
                  dangerouslySetInnerHTML={{ __html: bullet }}
                />
              </LabelSectionSourceWrapper>
            ))}
          </div>
        );
      }
    } else {
      return (
        <div>
          <SourceHeader />
          {sources.map((source, index) => {
            return (
              <LabelSectionSourceWrapper key={`source_${index}`}>
                <LabelSectionHeaderStyles>
                  {source.key}
                </LabelSectionHeaderStyles>
                {source.sources.map((bullet, bulletIndex) => {
                  return (
                    <LabelSectionSourceTextStyles
                      key={`source_${index}_${bulletIndex}`}
                      dangerouslySetInnerHTML={{ __html: bullet }}
                    />
                  );
                })}
              </LabelSectionSourceWrapper>
            );
          })}
        </div>
      );
    }
  }
  return "";
};

const Writeup = ({ label }) => {
  if (label.writeup) {
    const keys = Object.keys(label.writeup).sort();
    return keys.map((key) => {
      const blurb = label.writeup[key];
      return (
        <div key={key}>
          <LabelSectionHeaderStyles>{blurb.title}</LabelSectionHeaderStyles>
          {blurb.body.map((paragraph, index) => {
            return (
              <LabelSectionTextStyles
                dangerouslySetInnerHTML={{ __html: paragraph }}
                key={`criteria_${key}_${index}`}
              />
            );
          })}
        </div>
      );
    });
  }
  return null;
};

const Metadata = ({ label, parents }) => {
  //const slot = label?.network === "PODCAST" ? "frequency" : "timeslot";
  const slot = "timeslot";
  const metadata = label?.metadata;

  return (
    <MetadataWrapper>
      {label?.network === "TV" && metadata?.network?.body && (
        <NetworkView label={label} parents={parents} />
      )}
      {label?.network === "TV" &&
        label?.publisherType !== "TV_NETWORK" &&
        metadata[slot]?.body && (
          <MetadataText>
            {metadata[slot]?.body}
            {metadata?.length?.body ? (
              <MetadataTime>
                , &nbsp;
                <TimeslotIcon />
                {metadata?.length?.body}
              </MetadataTime>
            ) : null}
          </MetadataText>
        )}
    </MetadataWrapper>
  );
};

const NetworkView = ({ label, parents }) => {
  if (label?.network === "TV" && label?.publisherType !== "TV_NETWORK") {
    return (
      <MetadataText>
        Network&#8212;
        {parents?.length > 0 ? (
          <>
            {parents.map((parent, idx) => {
              return (
                <div>
                  <Link
                    to={`/partner/label/${parent.id}`}
                    dangerouslySetInnerHTML={{ __html: parent.identifier }}
                  />
                  {idx + 1 < parents.length ? <span>, &nbsp;</span> : ""}{" "}
                </div>
              );
            })}
          </>
        ) : (
          <>{label?.metadata?.network?.body}</>
        )}
      </MetadataText>
    );
  }
  return "";
};

const Footnote = ({ footnotes }) => {
  if (footnotes && footnotes.length > 0) {
    return (
      <div>
        <h2>Footnotes</h2>
        <OrderedList>
          {footnotes.map((footnote, index) => {
            return (
              <li
                key={`footnote${index}`}
                id={`fn_note-0${index > 9 ? index : `0${index + 1}`}`}
              >
                <span dangerouslySetInnerHTML={{ __html: footnote }} />
                <a
                  href={`#fn_ref-0${index > 9 ? index : `0${index + 1}`}`}
                  aria-label="Back to reference"
                >
                  ↩
                </a>
              </li>
            );
          })}
        </OrderedList>
      </div>
    );
  }
  return "";
};

const Label = ({ label, children, parents, childLoading }) => {
  const componentRef = useRef();
  const {
    network,
    publisherType,
    groupName,
    identifier,
    identifierAlt,
    sources,
  } = label;
  const isTVNetworkPublisher = publisherType === "TV_NETWORK";

  if (label && label.id) {
    return (
      <PageWrapper ref={componentRef}>
        <PrintLogo />
        <TitleStyle>
          {groupName ? `${groupName}` : identifierAlt || identifier}
        </TitleStyle>

        <LabelContainerStyles isTVNetworkPublisher={isTVNetworkPublisher}>
          <LabelBlurbStyles>
            {(network === "TV" || network === "PODCAST") && (
              <Metadata label={label} parents={parents} />
            )}
            {network === "WEB" && (
              <Actions item={label} el={componentRef} showLocale />
            )}

            <LabelTopLineDesecriptionStyles
              dangerouslySetInnerHTML={{
                __html: label.topline ? label.topline : "TK",
              }}
            />
          </LabelBlurbStyles>

          <div className="scoring">
            <RatingView label={label} children={children} />
          </div>
          <div className="label">
            {isTVNetworkPublisher && children && (
              <ChildLabels
                children={children}
                loading={childLoading}
                key={"children"}
              />
            )}

            <Writeup label={label} key={"writeup"} />
            <Byline label={label} key={"byline"} />
            <Source sources={sources} key={"source"} />
            <Footnote footnotes={label.footnotes} key={"footnote"} />
          </div>
        </LabelContainerStyles>
      </PageWrapper>
    );
  }
  return null;
};

export default Label;
