import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import PartnerFooter from "../components/PartnerFooter";
import AuthService from "../service/AuthService";
import PartnerHeader from "../components/PartnerHeader";
import SearchBar from "../components/Partners/Search/SearchBar/SearchBar";
import { Text } from "./Notice";

export const GlobalStyle = createGlobalStyle`
  /*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    margin: 0;
  }
  html {
  scroll-padding-top: 100px;
  scroll-behavior: smooth;
}
  html,
  body {
    height: 100%;
  }
  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }
  #root,
  #__next {
    isolation: isolate;
  }

  @font-face {
    font-family: "Sora";
    src: url("/fonts/Sora-VariableFont_wght.woff2")
        format("truetype supports variations"),
      url("/fonts/Sora-VariableFont_wght.woff2") format("truetype-variations"),
      url("/fonts/Sora-VariableFont_wght.ttf")
        format("truetype supports variations"),
      url("/fonts/Sora-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 1 999;
    font-display: swap;
  }

  :root {
    --primary-blue: #3355dd;
    --primary-blue-light: rgba(51, 85, 221, 0.3);
    --primary-black: #000;
    --primary-white: #fff;
    --primary-gray: rgba(0, 0, 0, 0.06);
    --primary-gray-light: rgba(0, 0, 0, 0.3);
    --primary-gray-disabled: rgba(0, 0, 0, 0.4);
    --primary-gray-dark: rgba(0, 0, 0, 0.7);
    --primary-red: #ff4444;
    -webkit-font-smoothing: antialiased;
  }

  html {
    --10px: 0.625rem;
    --12px: 0.75rem;
    --14px: 0.875rem;
    --15px: 0.9375rem;
    --16px: 1rem;
    --17px: 1.0625rem;
    --18px: 1.125rem;
    --19px: 1.1875rem;
    --20px: 1.25rem;
    --21px: 1.3125rem;
  }

  
  // stop page shift when content comes in for windows chrome

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) and (min-width: 960px) {
    html {
      overflow-y: scroll;
    }
  }

  body {
    font-family: "Sora", sans-serif;
    font-size: var(--14px);
    line-height: var(--20px);
    color: #000;
    display: flex;
    flex-direction: column;
    font-weight: 400;

    &.has-overlay {
      overflow: hidden;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    font-weight: 500;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 51px;
    margin-bottom: 50px;
  }

  a {
          color: var(--primary-blue);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
      
          ${Text} & {
            color: var(--primary-white);
            &:hover {
              text-decoration: underline;
            }
          }
        }

  th {
    font-weight: 500;
  }

  .main-container {
    max-width: ${(props) => (props.narrow ? "760px" : "1200px")};
    margin: 90px auto 0;
    min-height: 500px;
    /* min-width: 785px; */
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: ${(props) => (props.narrow ? "0" : " 0 20px")};

    @media screen and (max-width: 600px) {
      min-width: 300px;
      padding: 0 20px;
      margin-top: 35px;
    }

    &--search {
      isolation: isolate;
      margin-top: 140px;
      min-width: auto;
      max-width: 1200px;
      @media screen and (max-width: 600px) {
        margin-top: 72px;
        padding: 0 20px;
      }
    }
    &--label,
    &--settings {
      margin-top: 190px;
      /* padding: 0; */
      @media screen and (max-width: 600px) {
        margin-top: 120px;
      }
      @media print {
        margin-top: 30px;
      }
    }
   
  }

  [data-reach-dialog-content] {
    background: transparent !important;
    width: 520px;
    margin: 0 auto !important;
    padding: 0 !important;
    position: relative;
    /* top: 10vh; */
    @media screen and (max-width: 800px) {
      width: 520px !important;
    }
    @media screen and (max-width: 600px) {
      width: 90vw !important;
    }
  }

  [data-reach-dialog-content][type="advanced"] {
    width: auto;
  }
  
  [data-reach-dialog-content][type="sort"] {
    width: 60%;
  }

  [data-reach-dialog-overlay] {
    z-index: 22;
  }



/* reset */


.TooltipContent {
  border-radius: 4px;
  padding: 10px 15px;
  font-size: .775rem;
  line-height: 1.125rem;
  color: var(--violet11);
  background-color: rgb(238, 239, 252);;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  width: 20rem;
  max-height: var(--radix-tooltip-content-available-height);

  &.Tooltip-wide {
    width: 40rem;
  }
}
.TooltipContent[data-state='delayed-open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.TooltipContent[data-state='delayed-open'][data-side='left'] {
  animation-name: slideRightAndFade;
}



.TooltipArrow {
  fill: rgb(238, 239, 252);;
  
}

.TooltipTrigger {  
  all: unset;
  height: 15px;
}

sup[id^="fn"] a {
  text-decoration: none !important;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
 
`;

const MainLayout = ({ children, narrow }) => {
  const isMediaUrl = (path) => {
    const pattern = /\/media\/[a-z,0-9,-]{36}/;
    return pattern.test(path);
  };

  const location = useLocation();

  const principal = useSelector((state) => state.authentication.principal);
  const partner = AuthService.getInstance().isPartner(principal);
  const path = location.pathname;

  if (partner) {
    if (path === "/") {
      return <Navigate to="/partner/home" />;
    }

    return (
      <>
        <GlobalStyle />
        {path.startsWith("/partner/home") ||
        path.startsWith("/partner/settings") ||
        path === "/" ? (
          <PartnerHeader signedIn />
        ) : (
          <SearchBar large={true} header={true} />
        )}

        <div
          className={`main-container ${
            path.startsWith("/partner/label") ||
            path.startsWith("/partner/myth") ||
            path.startsWith("/partner/profile") ||
            path.startsWith("/partner/report")
              ? "main-container--label"
              : ""
          }`}
        >
          {/* for home page This is where we need to break out of container */}
          <Outlet />
        </div>
        {!path.startsWith("/signin") ? <PartnerFooter /> : null}
      </>
    );
    // }
  }

  return (
    <>
      <GlobalStyle narrow={narrow} />
      <PartnerHeader signedIn={false} />
      <div className="main-container">
        <Outlet />
      </div>
      {!path.startsWith("/signin") ? <PartnerFooter /> : null}
    </>
  );
};

export default MainLayout;
