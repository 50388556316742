import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";

import ReportMarker from "../common/ReportMarker";

import LoadingIcon from "../common/LoadingIcon";
import PrintLogo from "../common/PrintLogo";
import Actions from "../common/Actions";
import Byline from "../Byline";
import AuthService from "../../../service/AuthService";
import Unauthorized from "../Unauthorized/Unauthorized";
import TagManager from "react-gtm-module";
import { MetadataList, MetadataListWrapper } from "../Metadata";
import { getLocale } from "../../../common/_localeMessages";
import formattedDate from "../Helpers/formattedDate";
import { useReports } from "./useReports";
import {
  PageWrapper,
  ReportHeaderWrapper,
  ReportHeader,
  ReportContainerWrapper,
  ReportContainer,
  ContentContainer,
  AsideWrapper,
  ReportSubheader,
  ReportSectionTextStyles,
  ReportSectionHeaderStyles,
  AsideContainerStyles,
  AsideHeaderStyles,
  LabelTopLineDesecriptionStyles,
} from "./ReportStyles";
import mythMetadataDefinitions from "../../../../../../../../shared/resources/_myth_metadata.json";
import reportMetadataDefinitions from "../../../../../../../../shared/resources/_report_metadata.json";

const Writeup = ({ report }) => {
  if (report.writeup) {
    const keys = Object.keys(report.writeup).sort();
    return keys.map((key) => {
      if (key !== "topline description") {
        const blurb = report.writeup[key];
        return (
          <>
            {blurb.title !== "Body" ? (
              <ReportSectionHeaderStyles key={key}>
                {blurb.title}
              </ReportSectionHeaderStyles>
            ) : (
              ""
            )}
            {blurb.body.map((paragraph, index) => {
              return (
                <ReportSectionTextStyles
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                  key={`writeup_${key}_${index}`}
                />
              );
            })}
          </>
        );
      }
    });
  }
  return null;
};

const Footnote = ({ report }) => {
  if (report.footnotes && report.footnotes.length > 0) {
    return (
      <div>
        <ReportSubheader>Footnotes</ReportSubheader>
        {report.footnotes.map((footnote, index) => {
          return (
            <ReportSectionTextStyles
              key={`footnote${index}`}
              dangerouslySetInnerHTML={{ __html: footnote }}
            />
          );
        })}
      </div>
    );
  }
  return "";
};

const Report = (props) => {
  const dispatch = useDispatch();
  const principal = useSelector((state) => state.authentication?.principal);

  const reportId = useParams().reportId || props.reportId;
  const profileId = useParams().profileId || props.profileId;

  const { report, isLoading, isError } = useReports(reportId, profileId);

  useEffect(() => {
    if (report) {
      const tagManagerArgs = {
        dataLayer: {
          event: "PartnerView",
          user: principal?.username,
          partner: principal?.attributes?.partner,
          client_id: principal?.attributes?.client_id,
          view: "Label",
          content_type: "REPORT",
          content_title: report.title || report.identifier,
          content_locale: report.locale,
          tab: "",
        },
      };

      TagManager.dataLayer(tagManagerArgs);

      dispatch({
        type: "SET_LABEL_DOMAIN",
        payload: report.title || report.name,
      });
      dispatch({
        type: "SET_SEARCH_CONTEXT",
        payload: { value: "REPORT", label: "Reports" },
      });
    }
  }, [report, dispatch, principal]);

  const componentRef = useRef();

  return isLoading ? (
    <LoadingIcon />
  ) : AuthService.getInstance().hasOnlineReportAccess(principal) ? (
    <PageWrapper ref={componentRef}>
      <PrintLogo />
      <ReportHeaderWrapper>
        <ReportMarker large />
        <ReportHeader dangerouslySetInnerHTML={{ __html: report.title }} />
      </ReportHeaderWrapper>
      <ReportContainerWrapper>
        <Actions item={report} el={componentRef} showLocale />
        <ReportContainer>
          <ContentContainer>
            <LabelTopLineDesecriptionStyles
              dangerouslySetInnerHTML={{
                __html: report.topline,
              }}
            />

            <Writeup report={report} key={"writeup"} />
            <Footnote report={report} key={"footnote"} />
          </ContentContainer>
          <AsideWrapper>
            <AsideContainerStyles>
              {report.firstPublishedDate && (
                <section>
                  <AsideHeaderStyles>Published</AsideHeaderStyles>
                  {formattedDate(report.firstPublishedDate, getLocale(), {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    //timeZone: "UTC",
                  })}
                </section>
              )}

              <MetadataListWrapper
                value={report.metadata?.category?.body}
                metadataDef={reportMetadataDefinitions.category}
                context={"REPORT"}
              />

              <MetadataListWrapper
                value={report.metadata?.subject_tags?.body}
                metadataDef={reportMetadataDefinitions.subject_tags}
                context={"REPORT"}
              />
            </AsideContainerStyles>
            <Byline label={report} key={"byline"} />
          </AsideWrapper>
        </ReportContainer>
      </ReportContainerWrapper>
    </PageWrapper>
  ) : (
    <Unauthorized type="REPORT" />
  );
};

export default Report;
