import { useEffect } from "react";
import { useSelector } from "react-redux";
import TagManager from "react-gtm-module";

import { useDebouncedCallback } from "use-debounce";

import { useUserProfile } from "./useUserProfile";
import { useEditAlertSettings } from "./useEditAlertSettings";

import AuthService from "../../../service/AuthService";

import FollowingSettings from "./FollowingSettings";
import MythSettings from "./MythSettings";
import NewRatingsSettings from "./NewRatingsSettings";
import UpdatedRatingsSettings from "./UpdatedRatingsSettings";
import { AccountSectionHeader } from "../../Account/AccountStyles";
import ReportSettings from "./ReportSettings";
import DevelopingNarrativeSettings from "./DevelopingNarrativeSettings";

const EmailPreferences = ({ handleVisible }) => {
  const principal = useSelector((state) => state.authentication?.principal);

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "PartnerView",
        user: principal?.username,
        partner: principal?.attributes?.partner,
        client_id: principal?.attributes?.client_id,
        view: "Email Alert Settings",
        content_type: "",
        content_title: "",
        content_locale: "",
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  }, []);

  const authService = AuthService.getInstance();

  // GET EMAIL PREFS
  const { userProfile, error, isLoading } = useUserProfile();
  const { editEmailPreferences, loading, isError } = useEditAlertSettings();

  function handleChange(form, categories) {
    const formData = new FormData(form);
    const settingType = form.name;

    const alertOn = formData.get("alertOn");
    const trigger = formData.get("trigger");
    const frequency = formData.get("frequency");

    editEmailPreferences({
      alertOn,
      settingType,
      trigger,
      frequency,
      categories,
    });
  }

  function handleToggle(setting, value, form, categories) {
    const alertOn = value;
    const settingType = setting;
    const formData = new FormData(form);

    const trigger = formData.get("trigger");
    const frequency = formData.get("frequency");

    editEmailPreferences({
      alertOn,
      settingType,
      trigger,
      frequency,
      categories,
    });
  }

  return (
    !isLoading && (
      <>
        <AccountSectionHeader>Email Alert Settings</AccountSectionHeader>
        {authService.hasMythAccess(principal) && (
          <MythSettings
            principal={principal}
            mythAlertOn={userProfile?.mythAlertOn}
            mythTrigger={userProfile?.mythTrigger}
            mythCategories={userProfile?.mythCategories}
            mythFrequency={userProfile?.mythFrequency}
            handleChange={handleChange}
            handleVisible={handleVisible}
            handleToggle={handleToggle}
          />
        )}
        {authService.hasMythAccess(principal) && (
          <DevelopingNarrativeSettings
            developingNarrativeAlertOn={userProfile?.developingNarrativeAlertOn}
            developingNarrativeFrequency={
              userProfile?.developingNarrativeFrequency
            }
            handleChange={handleChange}
            handleVisible={handleVisible}
            handleToggle={handleToggle}
          />
        )}
        {authService.hasOnlineReportAccess(principal) && (
          <ReportSettings
            reportAlertOn={userProfile?.reportAlertOn}
            reportTrigger={userProfile?.reportTrigger}
            reportCategories={userProfile?.reportCategories}
            reportFrequency={userProfile?.reportFrequency}
            handleChange={handleChange}
            handleVisible={handleVisible}
            handleToggle={handleToggle}
          />
        )}
        {(authService.hasLabelAccess(principal) ||
          authService.hasTVAccess(principal) ||
          authService.hasPodcastAccess(principal)) && (
          <>
            <NewRatingsSettings
              ratingsOn={userProfile?.newAlertOn}
              ratingsFrequency={userProfile?.newRatingsFrequency}
              ratingsTriggers={userProfile?.newRatingsTrigger}
              handleChange={handleChange}
              handleVisible={handleVisible}
              handleToggle={handleToggle}
            />
            <UpdatedRatingsSettings
              ratingsOn={userProfile?.updatedAlertOn}
              ratingsFrequency={userProfile?.updatedRatingsFrequency}
              ratingsTriggers={userProfile?.updatedRatingsTrigger}
              handleChange={handleChange}
              handleVisible={handleVisible}
              handleToggle={handleToggle}
            />
            <FollowingSettings
              followingAlertOn={userProfile?.followingAlertOn}
              followingFrequency={userProfile?.followingFrequency}
              followingTrigger={userProfile?.followingTrigger}
              handleChange={handleChange}
              handleVisible={handleVisible}
              handleToggle={handleToggle}
            />
          </>
        )}
      </>
    )
  );
};

export default EmailPreferences;
