import * as Tooltip from "@radix-ui/react-tooltip";
import { InfoCircle } from "../../../common/svg/InfoCircle";

const Tip = ({ children, className }) => (
  <Tooltip.Provider>
    <Tooltip.Root>
      <Tooltip.Trigger className="TooltipTrigger">
        <InfoCircle />
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className={`TooltipContent ${className}`}>
          {children}
          <Tooltip.Arrow className="TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);

export default Tip;
