import styled from "@emotion/styled/macro";

const PageWrapper = styled.div`
  @page {
    margin: 60px;
  }
`;

const ReportContainerWrapper = styled.div`
  border-top: 6px solid rgba(0, 0, 0, 0.07);
  margin-top: 87px;
  padding-top: 60px;
  position: relative;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }

  @media print {
    margin-top: 20px;
  }
`;

const ReportContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(min-content, 760px) 1fr;
  grid-template-rows: min-content 3fr;
  grid-column-gap: 50px;

  @media (max-width: 924px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  @media print {
    display: block;
  }
`;

const ContentContainer = styled.div`
  @media screen and (max-width: 600px) {
    padding-top: 10px;
    margin-bottom: 20px;
  }
`;

const AsideWrapper = styled.div`
  max-width: 350px;
  min-width: 250px;
  justify-self: self-end;
  width: 100%;
  margin-top: -56px; // to align with locale badge
  @media (max-width: 924px) {
    max-width: 100%;
    justify-self: unset;
    grid-column: 1;
    grid-row: 2;
    margin-top: 0;
  }
`;

const ReportHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 600px) {
    align-items: flex-start;
  }

  @media print {
    margin-bottom: 0;
  }
`;

const ReportHeader = styled.h1`
  margin-bottom: 0;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media print {
    font-size: 30px;
    line-height: 40px;
  }
`;

const ReportSubheader = styled.h2`
  font-size: 16px;
  font-variation-settings: "wght" 600;
  line-height: 30px;
  margin-top: 10px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
`;

const ReportSectionTextStyles = styled.p`
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  white-space: pre-wrap;

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  a {
    text-decoration: none;
    color: #3355dd !important;

    &:hover {
      text-decoration: underline !important;
    }

    ul {
      list-style-type: inherit;
    }

    &:last-of-type {
      margin-bottom: 35px;
    }
  }
`;

const ReportSectionHeaderStyles = styled.h3`
  font-size: 20px;
  font-variation-settings: "wght" 600;
  line-height: 30px;
  margin-bottom: 10px;

  @media screen and (max-width: 600px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 5px;
  }
`;

const AsideContainerStyles = styled.aside`
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-top: 6px solid #000;
  border-radius: 2px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08);
  padding: 50px 20px 20px;
  margin-bottom: 45px;

  section {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 600px) {
    word-break: break-all;
    section {
      margin-bottom: 20px;
    }
  }
`;

const AsideHeaderStyles = styled.h4`
  font-size: 14px;
  font-variation-settings: "wght" 600;
  line-height: 18px;
  margin-bottom: 8px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const LabelTopLineDesecriptionStyles = styled.p`
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    line-height: 30px;
    font-variation-settings: "wght" 100;
    margin-bottom: 10px;
  }

  @media print {
    font-size: 18px;
    line-height: 30px;
    margin-top: 30px;
  }
`;

export {
  PageWrapper,
  ReportContainerWrapper,
  ReportContainer,
  ContentContainer,
  AsideWrapper,
  ReportHeaderWrapper,
  ReportHeader,
  ReportSubheader,
  ReportSectionTextStyles,
  ReportSectionHeaderStyles,
  AsideContainerStyles,
  AsideHeaderStyles,
  LabelTopLineDesecriptionStyles,
};
