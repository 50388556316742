import CheckboxTree from "./CheckboxTree";
import MythMetadata from "../../../../../../../../shared/resources/_myth_metadata";

// MythMetadata.category.values
const CategoryFilterFillupDefault = (value, selected, nodes) => {
  const newSelected = [...selected];
  nodes.map((node) => {
    if (node.value === value) {
      if (newSelected.indexOf(value) < 0) {
        newSelected.push(value);
      }
      if (node.children && node.children.length > 0) {
        node.children.map((child) => {
          if (newSelected.indexOf(child.value) < 0) {
            newSelected.push(child.value);
          }
        });
        const other = `${value}[other]`;
        if (newSelected.indexOf(other) < 0) {
          newSelected.push(other);
        }
      }
    } else {
      node.children.map((child) => {
        if (child.value === value) {
          if (newSelected.indexOf(child.value) < 0) {
            newSelected.push(child.value);
          }
        }
      });
    }
  });
  return newSelected;
};

export const CategoryFilterSetup = (value, selected) => {
  return CategoryFilterFillupDefault(
    value,
    selected,
    MythMetadata.category.values
  );
};

const MythCategoryChooser = ({ mythSettings, handleChange, principal }) => {
  const selected = mythSettings?.mythCategories || mythSettings || [];

  return (
    <CheckboxTree
      name="MythCategory"
      nodes={MythMetadata.category.values}
      enabledNodes={principal?.attributes?.myth_restriction?.category?.body}
      selected={selected}
      setSelected={(val) => {
        const event = {
          target: {
            name: "MythCategory",
            value: val,
          },
        };

        handleChange(event);
      }}
      hasOther={false}
    />
  );
};

export default MythCategoryChooser;
