import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import Activation from "../Voucher/Activation";
import AuthService from "../../service/AuthService";
import AccountSettings from "./AccountSettings";
import PaymentHistoryModule from "./PaymentHistoryModule";
import PaymentMethod from "./PaymentMethod";
import EmailPreferences from "../Partners/Settings/EmailPreferences";
import { ButtonWhiteAccountMenu } from "../FormElements/Button";

import {
  MainContent,
  Nav,
  NavItem,
  NavList,
  NavLink,
  Wrapper,
} from "./AccountStyles";

const AccountLayout = ({ children }) => {
  const location = useLocation();
  const navRef = useRef();

  const activePath = location.pathname.includes("preferences")
    ? null
    : location.pathname.split("/")[2] || "account";

  const paymentSection = useRef();
  const accountSection = useRef();
  const billingSection = useRef();
  const membershipSection = useRef();
  const redeemSection = useRef();
  const accountLink = useRef();

  const authService = AuthService.getInstance();
  const principal = useSelector((state) => state.authentication.principal);
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();
    navigate("/signout");
  };

  const handleActiveNav = (e) => {
    const navElement = document.querySelectorAll(".account-nav");
    navElement.forEach((navItem) => navItem.classList.remove("active"));
    e.target.classList.add("active");
  };

  const scrollToElement = (e, ref) => {
    if (e) {
      e.preventDefault();
      e.target.classList.add("active");
    }
    window.scrollTo({
      top: ref === accountSection ? 10 : ref.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  const getActiveSection = (activePath) => {
    switch (activePath) {
      case "paymentmethod":
        return paymentSection;
      case "account":
        return accountSection;
      case "billing":
        return billingSection;
      case "membership":
        return membershipSection;
      case "redeem":
        return redeemSection;
      default:
        return accountSection;
    }
  };

  useEffect(() => {
    if (activePath) {
      scrollToElement(null, getActiveSection(activePath));
    }
  }, [activePath]);

  const [intersectingEntries, setIntersectingEntries] = useState([]);

  const [containerY, setContainerY] = useState(null);

  useEffect(() => {
    const container = document.querySelector(".main-container");
    let previousY = container.getBoundingClientRect().y;

    const intervalId = setInterval(() => {
      const currentY = container.getBoundingClientRect().y;
      if (currentY !== previousY) {
        setContainerY(currentY);
        previousY = currentY;
      }
    }, 200);

    if (containerY <= 200 && containerY >= -100) {
      document.querySelector(".account-nav.active")?.classList.remove("active");
      document
        .querySelector("a[data-section=mythSettings]")
        ?.classList.add("active");
    }

    // Clean up function
    return () => clearInterval(intervalId);
  }, [containerY]);

  const handleActiveSettingsNav = (name) => {
    const emailSettingsNavElements =
      navRef.current?.querySelectorAll(`a[data-section]`);
    emailSettingsNavElements?.forEach((navItem) => {
      navItem.classList.remove("active");
    });
    const navElement = navRef.current.querySelector(
      `a[data-section="${name}"]`
    );
    navElement?.classList.add("active");
  };

  function handleVisible(entry) {
    setIntersectingEntries((prevComponents) => {
      const componentIndex = prevComponents.findIndex(
        (component) => component.target.name === entry.target.name
      );

      if (entry.isIntersecting) {
        if (
          componentIndex !== -1 &&
          prevComponents[componentIndex].intersectionRatio !==
            entry.intersectionRatio
        ) {
          // Replace component if it's intersecting, already in the array, and intersectionRatio has changed
          const newComponents = [...prevComponents];
          newComponents[componentIndex] = entry;
          return newComponents;
        } else if (componentIndex === -1) {
          // Add component if it's intersecting and not already in the array
          return [...prevComponents, entry];
        }
      } else if (!entry.isIntersecting && componentIndex !== -1) {
        // Remove component if it's not intersecting and is in the array
        return prevComponents.filter(
          (component) => component.target.name !== entry.target.name
        );
      }
      return prevComponents;
    });

    const entriesWithYPosGreaterThan140 = intersectingEntries.filter(
      (entry) => entry.target.getBoundingClientRect().y > 140
    );

    if (entriesWithYPosGreaterThan140.length > 0) {
      const entryWithSmallestYPos = entriesWithYPosGreaterThan140.reduce(
        (prevEntry, currEntry) => {
          return prevEntry.target.getBoundingClientRect().y <
            currEntry.target.getBoundingClientRect().y
            ? prevEntry
            : currEntry;
        }
      );

      handleActiveSettingsNav(entryWithSmallestYPos.target.name);
    }
  }

  return (
    <Wrapper>
      <Nav>
        <NavList>
          {authService.isPartner(principal) ? (
            <NavList ref={navRef}>
              {authService.hasMythAccess(principal) && (
                <NavItem>
                  <NavLink
                    className="account-nav"
                    data-section="mythSettings"
                    to="/partner/settings/preferences/falseNarrativeSettings"
                  >
                    False Narratives
                  </NavLink>
                </NavItem>
              )}
              {authService.hasMythAccess(principal) && (
                <NavItem>
                  <NavLink
                    className="account-nav"
                    data-section="developingNarrativeSettings"
                    to="/partner/settings/preferences/developingNarrativeSettings"
                  >
                    Developing Narratives
                  </NavLink>
                </NavItem>
              )}
              {authService.hasOnlineReportAccess(principal) && ( // is there a hasReportAccess method?
                <NavItem>
                  <NavLink
                    className="account-nav"
                    data-section="reportSettings"
                    to="/partner/settings/preferences/reportSettings"
                  >
                    Reports
                  </NavLink>
                </NavItem>
              )}
              {(authService.hasLabelAccess(principal) ||
                authService.hasTVAccess(principal) ||
                authService.hasPodcastAccess(principal)) && (
                <>
                  <NavItem>
                    <NavLink
                      className="account-nav"
                      data-section="newRatingsSettings"
                      to="/partner/settings/preferences/newRatingsSettings"
                    >
                      New Ratings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="account-nav"
                      data-section="updatedRatingsSettings"
                      to="/partner/settings/preferences/updatedRatingsSettings"
                      preventScrollReset={true}
                    >
                      Updated Ratings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="account-nav"
                      data-section="followSettings"
                      to="/partner/settings/preferences/followSettings"
                    >
                      Followed Ratings
                    </NavLink>
                  </NavItem>
                </>
              )}
            </NavList>
          ) : (
            <>
              <NavItem>
                <NavLink
                  to="/account"
                  onClick={(e) => handleActiveNav(e)}
                  className="account-nav active"
                  data-section="account"
                  ref={accountLink}
                >
                  <FormattedMessage
                    id="template.account"
                    defaultMessage="Account"
                  />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/account/membership"
                  onClick={(e) => handleActiveNav(e)}
                  className="account-nav"
                  data-section="membership"
                >
                  <FormattedMessage
                    id="template.membership"
                    defaultMessage={"Membership"}
                  />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/account/billing"
                  onClick={(e) => handleActiveNav(e)}
                  className="account-nav"
                  data-section="billing-history"
                >
                  <FormattedMessage
                    id="template.payment_history"
                    defaultMessage="Payment History"
                  />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/account/paymentmethod"
                  onClick={(e) => handleActiveNav(e)}
                  className="account-nav"
                  data-section="payment-information"
                >
                  <FormattedMessage
                    id="template.payment_method"
                    defaultMessage="Payment Method"
                  />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  to="/account/redeem"
                  onClick={(e) => handleActiveNav(e)}
                  className="account-nav"
                  data-section="redeem"
                >
                  <FormattedMessage
                    id="template.voucher.redeem.code"
                    defaultMessage="Redeem Access Code"
                  />
                </NavLink>
              </NavItem>
              <NavItem>
                <ButtonWhiteAccountMenu onClick={(e) => logout(e)}>
                  <FormattedMessage
                    id="template.log_out"
                    defaultMessage="Log Out"
                  />
                </ButtonWhiteAccountMenu>
              </NavItem>
            </>
          )}
        </NavList>
      </Nav>
      <MainContent>
        {authService.isPartner(principal) ? (
          <EmailPreferences handleVisible={handleVisible} />
        ) : (
          <>
            <AccountSettings
              elementRef={accountSection}
              secondaryRef={membershipSection}
            />
            <PaymentHistoryModule elementRef={billingSection} />
            <PaymentMethod elementRef={paymentSection} />
            <Activation elementRef={redeemSection} account={true} />
          </>
        )}
      </MainContent>
    </Wrapper>
  );
};

export default AccountLayout;
