function formattedDate(date, locale, options) {
  function formatDate(inputDateString, locale = "us-EN") {
    // Create a Date object from the ISO 8601 string
    const date = new Date(inputDateString);

    // Use options for formatting
    const defaultOptions = {
      year: (options && Object.hasOwn(options, "year ")) || "numeric",
      month: (options && Object.hasOwn(options, "month")) || "2-digit",
      day: (options && Object.hasOwn(options, "day")) || "2-digit",
      //timeZone: "UTC",
    };

    // Merge the options with the default options
    const mergedOptions = Object.assign(defaultOptions, options);

    // Format the date using locale-specific formatting
    const formattedDate = date.toLocaleString(locale, mergedOptions);

    return formattedDate;
  }

  if (date && locale) {
    return formatDate(date, locale);
  }
  return null;
}

export default formattedDate;
