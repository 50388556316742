import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled/macro";

const Checkbox = styled.input`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #000;
  border-radius: 2px;
  height: calc(20 / 16 * 1rem);
  width: calc(20 / 16 * 1rem);

  accent-color: #3355dd;

  &:checked {
    /* background-color: var(--primary-blue); */
    background-image: url("data:image/svg+xml,%3Csvg width='26px' height='26px' viewBox='0 0 26 26' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3Eii/status selected%3C/title%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fillRule='evenodd'%3E%3Cg id='ii/status-selected' fill='%233355DD'%3E%3Cpath d='M24 0 C25.1045695 -2.02906125e-16 26 0.8954305 26 2 L26 24 C26 25.1045695 25.1045695 26 24 26 L2 26 C0.8954305 26 1.3527075e-16 25.1045695 0 24 L0 2 C-1.3527075e-16 0.8954305 0.8954305 2.02906125e-16 2 0 L24 0 Z M18 9 L11.9987302 15 L8 11 L6 13 L12 19 L20 11 L18 9 Z' id='check'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-position: center;
    border: none;
  }
  &:indeterminate {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 26 26' style='enable-background:new 0 0 26 26;' xml:space='preserve'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' fill='%233355dd' d='M24,0c1.1,0,2,0.9,2,2v22c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V2c0-1.1,0.9-2,2-2H24z'/%3E%3Cpath fillRule='evenodd' clipRule='evenodd' fill='%23ffffff' d='M19.6,14.5H6.4c-0.1,0-0.1,0-0.1-0.1v-2.9c0-0.1,0-0.1,0.1-0.1h13.2c0.1,0,0.1,0,0.1,0.1L19.6,14.5 C19.7,14.5,19.6,14.5,19.6,14.5z'/%3E%3C/svg%3E%0A");
  }

  &:disabled {
    background-color: var(--primary-gray-disabled);
    border-color: var(--primary-gray-disabled);
    opacity: 0.5;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CategoryWrapper = styled.div``;

const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`;

const SubcategoryCheckboxes = styled.div`
  display: flex;
  gap: 5px;
`;

const SubcategoryWrapper = styled.div`
  margin: 0 0 30px 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;

  & label {
    font-weight: 400;
  }
`;

const Label = styled.label`
  margin: 0;
  padding: 0;
  font-size: 13px;
  cursor: pointer;

  &.disabled {
    color: var(--primary-gray-disabled);
    opacity: 1;
    cursor: default;
  }
`;

const CustomCheckbox = ({
  label,
  value,
  selected = [],
  setSelected = () => {},
  children = [],
  parent,
  hasOther = true,
  disabled = false,
  enabledNodes,
}) => {
  const [checked, setChecked] = useState(false);
  const checkboxRef = useRef();

  useEffect(() => {
    if (hasChildren) {
      if (!isChildChecked() && isChecked()) {
        const newSelected = selected.filter((item) => {
          return item !== value;
        });
        setSelected(newSelected);
      }
      switch (getChildCheckedState()) {
        case -1:
          checkboxRef.current.indeterminate = false;
          setChecked(false);
          break;
        case 0:
          checkboxRef.current.indeterminate = true;
          setChecked(false);
          if (selected.indexOf(value) > -1) {
            let newSelected = [...selected];
            newSelected = newSelected.filter((item) => {
              return item !== value;
            });
            setSelected(newSelected);
          }
          break;
        case 1:
          checkboxRef.current.indeterminate = false;
          setChecked(!disabled);
          break;
      }
    } else {
      setChecked(isChecked() && !disabled);
    }
  }, [selected]);

  const hasChildren = children && children.length > 0;

  const getChildCheckedState = () => {
    // -1: no child selected, 0: indeterminate state, 1: all selected
    if (hasChildren) {
      let totalNumberChildNode = children.length;
      if (hasOther) totalNumberChildNode++;

      const selectedChildren = children.filter((node) => {
        return selected.indexOf(node.value) > -1;
      });

      let currentNumberChildNode =
        hasOther && selected.indexOf(`${value}[other]`) > -1 ? 1 : 0;
      currentNumberChildNode += selectedChildren && selectedChildren.length;

      return currentNumberChildNode === 0
        ? -1
        : totalNumberChildNode === currentNumberChildNode
        ? 1
        : 0;
    }
    return 1;
  };

  const isChildChecked = () => {
    if (hasChildren) {
      const selectedChildren = children.filter((node) => {
        return selected.indexOf(node.value) > -1;
      });

      const hasChildren = selectedChildren && selectedChildren.length > 0;

      return (
        (hasOther && selected.indexOf(`${value}[other]`) > -1) || hasChildren
      );
    }
    return false;
  };

  const inParentChecked = () => {
    if (parent) {
      return selected.indexOf(parent) > -1;
    }
    return false;
  };

  const isChecked = () => {
    const index = selected.indexOf(value);
    let isChecked = index > -1;
    /*
     if (!isChecked) {
     isChecked = inParentChecked();
     isChecked = isChildChecked();
     if (isChecked) {
     updateChecked(true);
     }
     }
     */
    return isChecked;
  };

  const updateChecked = (checked) => {
    let newSelected = [...selected];
    if (checked) {
      if (newSelected.indexOf(value) < 0) {
        newSelected.push(value);
      }
      //newSelected.push(`${value}[other]`);
      if (hasChildren) {
        children.map((child) => {
          if (newSelected.indexOf(child.value) < 0) {
            newSelected.push(child.value);
          }
        });
        if (hasOther) {
          const otherVal = `${value}[other]`;
          if (newSelected.indexOf(otherVal) < 0) {
            newSelected.push(otherVal);
          }
        }
      }
      //if (parent && newSelected.indexOf(parent) < 0) newSelected.push(parent);
    } else {
      newSelected = newSelected.filter((item) => {
        return item !== value && item !== `${value}[other]`;
      });
      children.map((child) => {
        newSelected = newSelected.filter((item) => {
          return item !== child.value;
        });
      });
    }
    setSelected(newSelected);
  };

  const onClick = (event) => {
    const checked = event.target.checked;
    updateChecked(checked);
  };

  //console.log(value + "::" + disabled);
  return (
    <CheckboxWrapper>
      {hasChildren ? (
        <CategoryWrapper>
          <Category>
            <Checkbox
              ref={checkboxRef}
              type="checkbox"
              value={value}
              checked={checked}
              onChange={onClick}
              id={label}
              parent={parent}
              disabled={disabled}
            />
            <Label htmlFor={label} className={disabled ? "disabled" : ""}>
              {label}
            </Label>
          </Category>
          <SubcategoryWrapper>
            <CheckboxTree
              nodes={children}
              selected={selected}
              setSelected={setSelected}
              parent={value}
              hasOther={hasOther}
              disabled={disabled}
              enabledNodes={enabledNodes}
            />
          </SubcategoryWrapper>
        </CategoryWrapper>
      ) : (
        <SubcategoryCheckboxes>
          <Checkbox
            ref={checkboxRef}
            type="checkbox"
            value={value}
            checked={checked}
            onChange={onClick}
            id={label}
            parent={parent}
            disabled={disabled}
          />
          <Label htmlFor={label} className={disabled ? "disabled" : ""}>
            {label}
          </Label>
        </SubcategoryCheckboxes>
      )}
    </CheckboxWrapper>
  );
};

const CheckboxTree = ({
  name = "",
  nodes = [],
  enabledNodes,
  disabled = false,
  selected = [],
  setSelected = () => {},
  parent,
  hasOther = true,
}) => {
  let enabledOnes = [];
  let enabled = !disabled;
  //console.log(">>>>>>>>>>>>>>>>>>>");
  //console.log(enabledNodes);

  if (enabled) {
    const includedVals = nodes
      .filter((node) => enabledNodes && enabledNodes.includes(node.value))
      .map((node) => node.value);
    if (includedVals.length > 0) {
      enabledOnes = includedVals;
    } else {
      enabledOnes = nodes.map((node) => node.value);
    }
  }
  return (
    <>
      {nodes.map((node) => {
        return (
          <>
            <CustomCheckbox
              key={node.value}
              label={node.label}
              value={node.value}
              children={node.children}
              selected={selected}
              setSelected={setSelected}
              parent={parent}
              hasOther={true}
              disabled={!enabledOnes.includes(node.value)}
              enabledNodes={enabledNodes}
            />
          </>
        );
      })}
      {hasOther ? (
        <>
          <CustomCheckbox
            key={`${parent}[other]`}
            label="Other"
            value={`${parent}[other]`}
            selected={selected}
            setSelected={setSelected}
            parent={parent}
            hasOther={hasOther}
            disabled={disabled || enabledOnes?.length !== nodes?.length}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default CheckboxTree;
