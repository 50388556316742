import { useQuery } from "@tanstack/react-query";
import ContentService from "../../../service/ContentService";
import developingMythWriteup from "../../../../../../../../shared/resources/_developing-myth-writeup.json";
import contentService from "../../../service/ContentService";

const contentServiceInstance = ContentService.getInstance();

export function useMyths(profileId, mythId, mythMainId) {
  const {
    data: myth,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["myth", profileId, mythId, mythMainId],
    queryFn: () => getData(profileId, mythId, mythMainId),
  });

  if (myth?.developing) {
    myth.writeup = developingMythWriteup;
  }

  return {
    myth,
    firstAppeared: myth?.metadata?.date_first_appearance?.body
      ? Array.isArray(myth?.metadata?.date_first_appearance?.body)
        ? myth?.metadata?.date_first_appearance?.body[0]
        : myth?.metadata?.date_first_appearance?.body
      : null,
    isLoading,
    isError,
    error,
  };
}

async function getData(profileId, mythId, mythMainId) {
  let getMyth;
  if (profileId) {
    getMyth = contentServiceInstance.getMythByProfileId(profileId);
  } else if (mythId) {
    getMyth = contentServiceInstance.getMyth(mythId);
  } else {
    getMyth = contentServiceInstance.getMythByMythMainId(mythMainId);
  }

  return await getMyth;
}

export function useMythExampleLinks(myth) {
  const {
    data: mythExampleLinksData,
    isLoading: isMythExampleLinksLoading,
    isError,
  } = useQuery({
    queryKey: ["mythExampleLinks", myth],
    queryFn: () => getMythExampleLinks(myth),
    enabled: !!myth,
  });

  return {
    mythExampleLinks: mythExampleLinksData?.links,
    profiles: mythExampleLinksData?.associated,
    isMythExampleLinksLoading,
    isError,
  };
}

async function getMythExampleLinks(myth) {
  return await contentServiceInstance.getLabelMythExampleLinksByMyth(
    myth.mythProfileId
  );
}

export function useMythMedia(myth) {
  const {
    data: mythMedia,
    isLoading: isMythMediaLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ["mythMedia", myth],
    queryFn: () => getMythMedia(myth),
    enabled: !!myth,
  });

  return {
    mythMedia: mythMedia,
    isMythMediaLoading,
    error,
    isError,
  };
}

async function getMythMedia(myth) {
  return await contentServiceInstance.getMythMediaByMyth(myth.mythProfileId);
}
