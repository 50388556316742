import { FilterContainer } from "./ui/FilterContainer";
import LocaleFilter from "./AdvancedSearchFilters/LocaleFilter";
import MythCategoriesFilter from "./AdvancedSearchFilters/MythCategoriesFilter";
import ContentService from "../../../../service/ContentService";
import MultiSelectFilter from "./ui/MultiSelectFilter";
import metadata from "../../../../../../../../../shared/resources/_myth_metadata.json";
import countries from "../../../../../../../../../shared/resources/_country.json";
import React from "react";
import ScoreFilter from "./AdvancedSearchFilters/ScoreFilter";
import DateRangeFilter from "./ui/DateRangeFilter";

const loadOptions = (inputValue) => {
  return ContentService.getInstance()
    .getDistinctiveMythMetadataValue("subject_tags", inputValue)
    .then((response) => {
      return response.map((v) => {
        return {
          label: v,
          value: v,
        };
      });
    });
};

const harmRiskOptions = metadata.harm_risk.values.map((harm_risk) => {
  return {
    type: "harm_risk",
    value: harm_risk,
    label: harm_risk,
  };
});

const countryOptions = countries.map((c) => {
  return {
    type: "country",
    value: c.id,
    label: c.label,
  };
});

const manipulatedMedia = metadata.manipulated_media.values.map((element) => {
  return {
    type: "manipulated_media",
    value: element,
    label: element,
  };
});

export default ({
  principal,
  selectedFilters,
  openFilters,
  handleSelectChange,
  handleUI,
  handleSelectAndRadioChange,
  handleRangeChange,
  clear,
}) => (
  <div className="filters-wrapper">
    <FilterContainer
      filter={selectedFilters.subject_tags}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        async={true}
        loadOptions={loadOptions}
        onChange={handleSelectChange}
        filter={selectedFilters.subject_tags}
        principal={principal}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.locale}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <LocaleFilter
        onChange={handleSelectChange}
        principal={principal}
        filter={selectedFilters?.locale}
        exclude={["en_ALL"]}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.harm_risk}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={harmRiskOptions}
        onChange={handleSelectChange}
        filter={selectedFilters?.harm_risk}
      />
    </FilterContainer>

    <FilterContainer
      name="Categories"
      handleClear={clear}
      smallerContext
      align={"flex-start"}
      toggleContainer={handleUI}
      isContainerOpen={openFilters.has("categories")}
      filter={selectedFilters.categories}
    >
      <MythCategoriesFilter
        onChange={handleSelectAndRadioChange}
        value={selectedFilters?.categories?.value}
        principal={principal}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.manipulated_media}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={manipulatedMedia}
        onChange={handleSelectChange}
        filter={selectedFilters?.manipulated_media}
        principal={principal}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.myth_location}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={countryOptions}
        onChange={handleSelectChange}
        filter={selectedFilters?.myth_location}
        principal={principal}
      />
    </FilterContainer>

    <FilterContainer
      filter={selectedFilters?.spread_location}
      handleClear={clear}
      toggleContainer={handleUI}
    >
      <MultiSelectFilter
        options={countryOptions}
        onChange={handleSelectChange}
        filter={selectedFilters?.spread_location}
        principal={principal}
      />
    </FilterContainer>
    <FilterContainer
      filter={selectedFilters?.first_appearance_date}
      handleClear={clear}
    >
      <DateRangeFilter
        daveValue={selectedFilters?.first_appearance_date?.value}
        minDate=""
        defaultStartDate=""
        defaultEndDate=""
        onChange={(val) => {
          console.log("onChange");
          handleRangeChange(selectedFilters?.first_appearance_date, val);
        }}
      />
    </FilterContainer>
  </div>
);
