import { useEffect, useRef, useState } from "react";
import { useIntersectionObserver } from "@uidotdev/usehooks";

import useScrollOnParams from "./useScrollOnParams";
import SettingsToggle from "../common/SettingsToggle";
import RadioButton from "../common/ui/RadioButton";

import {
  FieldsetStyle,
  Section,
  SectionDescription,
  SectionTitles,
} from "./SettingsStyles";
import { AccountDetailsHeader } from "../../Account/AccountStyles";

const FollowingSettings = ({
  followingAlertOn,
  followingFrequency,
  followingTrigger,
  handleChange,
  handleVisible,
  handleToggle,
}) => {
  const [initialFollowAlertOn] = useState(followingAlertOn);

  const [formRef, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "0px",
  });

  const divRef = useRef(null);
  useScrollOnParams(divRef, "type", "followSettings", 100);

  useEffect(() => {
    if (entry) {
      handleVisible(entry);
    }
  }, [entry, handleVisible]);

  function handleRadioChange() {
    handleChange(formRef.current);
  }

  function toggleSettings() {
    handleToggle("followSettings", !followingAlertOn, formRef.current);
  }

  return (
    <div ref={divRef}>
      <form name="followSettings" ref={formRef} id="followSettings">
        <input
          hidden={true}
          name="alertOn"
          value={followingAlertOn}
          onChange={handleChange} // so that react doesn't complain about uncontrolled inputs
        />
        <SettingsToggle
          callback={toggleSettings}
          initialValue={initialFollowAlertOn}
        >
          <Section>
            <AccountDetailsHeader>Followed Ratings</AccountDetailsHeader>
            <SectionDescription>
              Use the settings below to choose when to receive alerts about
              source ratings that you follow. To follow a rating, navigate to
              the source's rating in this dashboard and click the grey "Follow"
              button.
            </SectionDescription>
            <SettingsToggle.Controls>
              <FieldsetStyle>
                <SectionTitles>Followed Ratings Alerts</SectionTitles>
                <SectionDescription>
                  Choose when you want to be alerted about changes to ratings
                  you follow:
                </SectionDescription>
                <RadioButton
                  checked={followingTrigger === "ANY"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="ANY"
                  label="All updates to followed ratings"
                />
                <RadioButton
                  checked={followingTrigger === "SCORE"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="SCORE"
                  label="Only updates with score or criteria changes"
                />
                <RadioButton
                  checked={followingTrigger === "RATING"}
                  name="trigger"
                  onChange={handleRadioChange}
                  value="RATING"
                  label="Only updates with overall rating level changes"
                />
              </FieldsetStyle>
              <FieldsetStyle>
                <SectionTitles>
                  Followed Content Notification Frequency
                </SectionTitles>
                <RadioButton
                  checked={followingFrequency === "WEEKLY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="WEEKLY"
                  label="Notify me with a weekly email digest"
                />
                <RadioButton
                  checked={followingFrequency === "DAILY"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="DAILY"
                  label="Notify me with a daily email digest"
                />
                <RadioButton
                  checked={followingFrequency === "INSTANT"}
                  name="frequency"
                  onChange={handleRadioChange}
                  value="INSTANT"
                  label="Notify me with an immediate email alert"
                />
              </FieldsetStyle>
            </SettingsToggle.Controls>
          </Section>
        </SettingsToggle>
      </form>
    </div>
  );
};

export default FollowingSettings;
